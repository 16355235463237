:root {
    --main-color: rgb(13, 81, 159);
    --large-font-size: x-large;
    --font-weight: 800;
    --font-size: larger;
}

.active{
    background-color:  var(--main-color)  !important;
}

.option-card{
    cursor: pointer;
}
label{
    cursor: pointer;
    width: 100% !important;
    height: 100% !important;
}
.logo-holder{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.QusetionContainer{
    height: 500px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
}
.Col-Lable{
    color: white;
}
.Col-Mark{
    width: 100% !important;
    height: 115% !important;
    position: relative;
    left: 0px;
    top: 0;
    padding: 0 !important;
}

.form-check-label {
    padding: 25px;
}
.QustionForm{
    position: absolute;
    bottom: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: flex;
    height: 40%;
    height: 321px;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    width: 100%;
}
.Back-Btn{
    background-color: rgb(128 128 128) !important;

}

.Back-Btn:hover{
    background-color: rgb(13, 81, 159) !important;
}
.TextPos{
    position: absolute;
    top: 50%; right: 50%;
    transform: translate(50%,-50%);
    /* line-break: anywhere; */
    width: 100%;
    padding: 10px;
}
.displayNON{
    display: none;
}

.input{
    width: 280px;
    height: 100%;
    border-radius: 10px;
    border-color: var(--main-color);
    color: var(--main-color);
    font-size: 16px;
    font-weight: 700;
}
.Quscard{
    width: calc((75% / 2) - 25px);
    height: 13rem !important;
    padding: 10px;
    color: rgb(13, 81, 159);
    font-weight: bolder;
    border: 3px solid;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    direction: rtl;
    margin-bottom: 20px;
    margin-top: 20px;
}
.questionsCon{
    width: 90% ;
}
.button{
    width: 108px !important;
    height: 39px !important;
    padding: 0 !important;
}
.ImgRes{
    object-fit: cover;
    max-width: 230px;
    height: auto;
}

/* ////// */
.SentenceContaner{
    width: 75%;
    height: 155px;
    display: flex;
    margin: 18px auto 0 auto;
    direction: rtl;
}
.SentAndIcon{
    background-color: #e9e9e9;
    margin: 12px;
    width: calc(100% / 3 - 12px);
    border-radius: 8px;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.SentIcon{
    width: 43px;
    height: 30%;
    margin-left: 43%;
    margin-top: 13px;
}
.Sentence{
    height: 70%;
    font-size: 14px;
}
.question-text{
    color:  rgb(13, 81, 159);
    font-weight: 600;
}
.cardContaner{
    width: 100%;
    justify-content: center;
    align-items: center;
}
.questionsCon {
    width: 100%;
    flex-direction: row;
}
h4{
    color: rgb(13, 81, 159) !important  ;
}
.BackgrounQustionsCard{
    background-color: rgb(13, 81, 159);
}
@media (max-width: 750px) {
    .SentenceContaner{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .SentAndIcon{
        width: 100%;
        padding: 20px;
        flex-direction: row;
    }
    .QustionForm{
        margin-bottom: 16px;
        height: 480px;
    }
    .questionsCon{
        flex-direction: column !important;
        align-items: center;
    }
    .Quscard{
        width: 90%;
        padding: 16px;
        height: 10rem  !important;
        margin: 20px 0 20px 0 !important;
    }
    .QusetionContainer{
        height: 940px;
    }
}