@import url(http://fonts.googleapis.com/earlyaccess/droidarabickufi.css);
@import url('https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400&family=Caprasimo&family=Changa:wght@500;600&family=Open+Sans:wght@400;600&family=Pacifico&family=Roboto+Condensed:wght@300;400;700&family=Roboto+Slab:wght@100;200;400&family=Rubik&display=swap');
:root{
    --BackGround-color-Blue: #336ea1;
    --BackGround-color-Blue-Bold: #1b4561;
    --BackGround-color-Blue-Sco: #58b2d8;
    --BackGround-color-Red:#953834;
    --BackGround-color-Red-Sco:#de6b64;
    --BackGround-color-Gray:#585a5b;
    --BackGround-color-Gray-Sco: #989a9b;
    --BackGround-color-Green:#579e52;
    --BackGround-color-Green-Sco:#83be68;
    --Font-Color : #357a9b;
    --Bold-Font-Family: "DIN Next LT Arabic Bold", sans-serif;
    --Regular-Font-Family: "DIN Next LT Arabic Regular", serif;
    --Black-Font-Family: "DIN Next LT Arabic Black", serif;
}
@font-face {
    font-family: "DIN Next LT Arabic Bold";
        src: local("DINNextLTArabic-Bold-2"),
        url( "../../../Media/Fonts/DINNextLTArabic-Bold-2.ttf") format("truetype");
}
@font-face {
    font-family: "DIN Next LT Arabic Black";
        src: local("DINNextLTArabic-Bold-2"),
        url( "../../../Media/Fonts/DINNextLTArabic-Black-4.ttf") format("truetype");
}
@font-face {
    font-family: "DIN Next LT Arabic Regular";
        src: local("DINNextLTArabic-Bold-2"),
        url( "../../../Media/Fonts/DINNextLTArabic-Regular-3.ttf") format("truetype");
}

.H1Text{
    direction: rtl;
}
html[lang="en"] #twindix span, 
html[lang="en"] #twindix h1,
html[lang="en"] #twindix div {
    direction: ltr !important;
}

html[lang="en"] #twindix .H1Text {
    text-align: left !important;
    font-size: 33px;
    width: 87%;
}

html[lang="en"] #twindix .BigText {
    text-align: left !important;
    left: 80px;
}

html[lang="en"] #twindix .PhraseText span {
    font-size: 17px !important;
}

html[lang="en"] #twindix .BoxsContaner .BigBox .DetilsBoxs span {
    font-size: 14px !important;
}

html[lang="en"] #twindix .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(4) span,
html[lang="en"] #twindix .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(1) span {
    top: 31% !important;
}

html[lang="en"] #twindix .BtnsContaner .BtnCon .ButtonsText div:nth-child(3),
html[lang="en"] #twindix .BtnsContaner .BtnCon .ButtonsText div:nth-child(1) {
    font-size: 18px !important;
}

html[lang="en"] #twindix .BtnsContaner .BtnCon .ButtonsText .left,
html[lang="en"] #twindix .BtnsContaner .BtnCon .ButtonsText .Right {
    font-size: 9px !important;
}

html[lang="en"] #twindix .ManyBoxesBox .LiftBox .BottumTitle,
html[lang="en"] #twindix .ManyBoxesBox .LiftBox .TopTitle {
    font-size: 10px !important;
    width: 110% !important;
    left: 0px !important;
}

html[lang="en"] #twindix .ManyBoxesBox .RightBox .BOX span {
    font-size: 8.5px !important;
    text-align: left;
}

.ManyPhraseBoxline{
    width: 70%;
    height: 2px;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 76px;
    background: white;
    border-radius: 30px;
}
.ManyPhraseBoxlineLast{
    width: 70%;
    height: 2px;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 76px;
    background: white;
    border-radius: 30px;
}
.ManyPhraseBoxlineNon{
    display: none;
}
.ManyPhraseBorderbottom{
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
}
.ManyPhraseBordertop{
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
}
.Canvas{
    width: 25px;
    margin-left: 23px;
    margin-right: 20px;
}
.BackGroundBlueBold{
    background-color: var(--BackGround-color-Blue-Bold);
}
.BackGroundBlueSco{
    background-color: var(--BackGround-color-Blue-Sco);
}
.BackGroundBlackeBold{
    background-color: var(--BackGround-color-Gray);
}
.BackGroundBlackSco{
    background-color: var(--BackGround-color-Gray-Sco);
}
.BackGroundgreeneBold{
    background-color: var(--BackGround-color-Green);
}
.BackGroundgreenSco{
    background-color: var(--BackGround-color-Green-Sco);
}
.BackGroundRedBold{
    background-color: var(--BackGround-color-Red);
}
.BackGroundRedSco{
    background-color: var(--BackGround-color-Red-Sco);
}

.ReportCoverF{
    width: 80%;
    height: 1500px;
}
.ReportCoverF span{
    position: absolute;
    top: 0;
    left:35%;
    width: 350px;
    height: 350px;
}
.loading-indicator {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--BackGround-color-Blue);
    color: white;
    font-size: 30px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}
.loading-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Arial, sans-serif;
    /* color: #333; Adjust the color as needed */
}

.loading-indicator::after {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid #ccc; /* Adjust the border color as needed */
    border-top: 4px solid #3498db; 
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.LayerForRepport{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #336ea17d;
    z-index: 100;
}
    /* START GLOPAL */
    .PDF{
        width: 100%;
        height: 100%;
        /* margin-left: 45px; */
    }
    .ImgReport{
        width: 100% ;
        height: 100%;
    }
    .AsidLogo{
        position: absolute;
        top: 50px;
        right: 50px;
        width: 250px;
        height: auto;
    }
    .PDF .BackGroundImge{
        width: 100%;
        height: 100%;
    }
    .DisplayNON{
        display: none;
    }
    .ManyBoxTransparent{
        background-color: transparent;
    }
    .ManyBoxBlue{
        background-color: var(--BackGround-color-Blue);
    }
    .ManyBoxGray{
        background-color: var(--BackGround-color-Gray);
    }
    .ManyBoxRed{
        background-color: var(--BackGround-color-Red);
    }
    .ManyBoxGreen{
        background-color: var(--BackGround-color-Green);
    }
    .ManyBoxsTop{
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        transform: translateY(23px);
    }
    .ManyBoxTopCol-Blue{
        background-color: var(--BackGround-color-Blue);
    }
    .ManyBoxTopCol-Gray{
        background-color: var(--BackGround-color-Gray);
    }
    .ManyBoxTopCol-Red{
        background-color: var(--BackGround-color-Red);
    }
    .ManyBoxTopCol-Green{
        background-color: var(--BackGround-color-Green);
    }
    .ManyBoxsBottom{
        border-Bottom-left-radius: 8px;
        border-Bottom-right-radius: 8px;
        background-color: var(--BackGround-color-Sco);
        transform: translateY(73px);
    }
    .ManyBoxBottomCol-Blue{
        background-color: var(--BackGround-color-Blue-Sco);
    }
    .ManyBoxBottomCol-Gray{
        background-color: var(--BackGround-color-Gray-Sco);
    }
    .ManyBoxBottomCol-Red{
        background-color: var(--BackGround-color-Red-Sco);
    }
    .ManyBoxBottomCol-Green{
        background-color: var(--BackGround-color-Green-Sco);
    }
    
    .ManyBoxPostion-Blue-1 {
        position: absolute;
        left: 135px;
        top: 259px;
    }
    .ManyBoxPostion-Blue-2 {
        position: absolute;
        left: 135px;
        top: 609.5px;
    }
    .ManyBoxPostion-Blue-3 {
        position: absolute;
        left: 135px;
        top: 960px;
    }
    .ManyBoxPostion-Black-1 {
        position: absolute;
        left: 135px;
        top: 221px;
    }
    .ManyBoxPostion-Black-2 {
        position: absolute;
        left: 135px;
        top: 569px;
    }
    .ManyBoxPostion-Black-3 {
        position: absolute;
        left: 135px;
        top: 917px;
    }
    .ManyBoxPostion-Red-1 {
        position: absolute;
        left: 135px;
        top: 192px;
    }
    .ManyBoxPostion-Red-2 {
        position: absolute;
        left: 135px;
        top: 539px;
    }
    .ManyBoxPostion-Red-3 {
        position: absolute;
        left: 135px;
        top: 886px;
    }
    .ManyBoxPostion-Green-1 {
        position: absolute;
        left: 135px;
        top: 206px;
    }
    .ManyBoxPostion-Green-2 {
        position: absolute;
        left: 135px;
        top: 553px;
    }
    .ManyBoxPostion-Green-3 {
        position: absolute;
        left: 135px;
        top: 894px;
    }

    .IntroBatrn{
        position: absolute;
        top: 157px;
        width: auto;
        height: auto;
        max-width: 85%;
        margin-left: 75px;
    }
    .ContanerForAllStrep{
        position: absolute;
        top: 161px;
        width: 100%;
        height: auto;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .IntroBatrn{
        left: 0 !important;
        top: 0 !important;
        margin-left: 0 !important;
        position: static !important; 
    }
    .StrepCountaner{
    width: 105%;
    height: auto; 
	background-image: url("../../../Media/Strep/streb.webp");
	background-repeat: no-repeat;
    background-size:100% 150%;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.StrepCountaner div{
    font-size: 30px;
    font-weight: 900;
    font-family: 'DIN Next LT Arabic Bold', sans-serif;
    color: white;
    padding: 12px 40px 12px 40px;
    width: 100%;
    height: 100%;
    position: relative;
    top: -5px;
    direction: rtl;
}

div,
span{
    /* direction: rtl; */
}
.Cover ,
.Intro,
.catalogue,
.ManyGraphs-1,
.GenralBigText,
.SkillsScoCover,
.GoodSkillEmpty,
.BigOneGraph-1,
.BigOneGraph-2,
.BigOneGraph-3,
.BigOneGraph-4,
.BigOneGraph-5,
.BigOneGraph-6,
.BigOneGraph-7,
.ManyPhraseInSkills-1,
.ManyPhraseInSkills-2,
.ManyPhraseInSkills-3,
.ManyPhraseInSkills-4,
.ManyPhraseInSkills-3,
.ManyPhraseInSkills-4,
.Empaty-3Report1,
.Empaty-4Report2,
.Empaty-5Report2,
.Empaty-6Report2,
.Empaty-7Report2,
.Empaty-8Report3,
.Empaty-9Report3,
.ScoCover-2,
.ScoCover-3,
.ScoCover-4,
.ManyBox-1Report-2,
.ManyBox-2Report-3,
.ManyBox-3Report-3,
.ManyBox-4Report-3,
.Btn-1,
.Btn-2,
.Btn-3,
.Btn-4,
.Btn-5,
.ManyBox-1,
.ManyBox-2,
.ManyBox-3,
.ManyBox-4,
.ManyBox-5,
.ManyBox-6,
.ManyBox-7,
.ManyBox-8,
.ManyBox-9,
.ManyBox-10,
.ManyBox-11,
.ManyBox-12,
.FinlePage
{
    width: 100%;
    height: 1430px;
}

/* .ManyGraphs-1 > *:not(img),
.BigOneGraph-1 > *:not(img) {
    transform: translateY(11.2px);
} */
/* END GLOPAL */