    /* START REPORT 4 */
            /* Start ScoCover-4 */
            .ScoCover-4{
                position: relative;
            }
            .ScoCover-4 .Text{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 42px;
                font-weight: 900;
                font-family: var(--Regular-Font-Family);
                color: white;
                text-align: center; 
                white-space: nowrap; 
                line-height: 1.2;
                font-family: var(--Bold-Font-Family);
            }
            /* End ScoCover-4 */
            /* Start BigOneGraph-4 */
        .BigOneGraph-4{
            position: relative;
        }
        .BigOneGraph-4 .IntroBatrn{
            position: absolute;
            left: 14%;
        } 
        .BigOneGraph-4 .TopTitle{
            position: absolute;
            top: 21%;
            width: 100%;
            height: 51px;
            display: flex;
            justify-content: center;
        } 
        .BigOneGraph-4 .TopTitle span{
            width: auto;
            height: auto;
            background-color: var(--BackGround-color-Blue);
            font-size: 23px;
            font-weight: 600;
            font-family: var(--Bold-Font-Family);
            color: white;
            padding: 8px;
            border-radius: 12px;
        } 
        .BigOneGraph-4 .BottomTitle{
            position: absolute;
            bottom: 69px;
            width: 100%;
            height: 51px;
            display: flex;
            justify-content: center;
        } 
        .BigOneGraph-4 .BottomTitle div{
            width: auto;
            margin: auto;
            background-color: var(--BackGround-color-Blue-Sco);
            font-size: 23px;
            font-weight: 600;
            font-family: var(--Bold-Font-Family);
            color: white;
            padding: 8px;
            border-radius: 12px;
            text-align: center;
        } 

        .BigOneGraph-4 .Graph-1{
            position: absolute;
            top: 482px;
            left: 346px;
            width: 420px;
            height: 714px;
            border-radius: 12px;
        }
        .BigOneGraph-4 .Graph-1 .Graph-Top-1 {
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: space-between;
            padding: 0 55px; /* Total space on both sides */
        }
        
        .BigOneGraph-4 .Graph-1 .Graph-Top-1 .BigCanvas {
            width: 65px; /* Set the fixed width */
            position: relative;
            background-color: var(--BackGround-color-Blue);
            margin-top: auto;
            top: -1px;
        }
        .BigOneGraph-4 .Graph-1 .Graph-Bottom-1{ 
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: space-between;
            padding: 0 55px; /* Total space on both sides */
        }
        .BigOneGraph-4 .Graph-1 .Graph-Bottom-1 .BigCanvas {
            width: 65px; /* Set the fixed width */
            position: relative;
            background-color: var(--BackGround-color-Blue-Sco);
            margin-top: 1px;
        }
        .BigOneGraph-4 .Graph-1 .TopBox{
            position: absolute;
            top: -110px;
            width:430px;
            height: 100px;
            font-family: var(--Regular-Font-Family);
        }
        .BigOneGraph-4 .Graph-1 .TopBox div:nth-child(1){
            /* width: 100%; */
        }
        .BigOneGraph-4 .Graph-1 .TopBox div:nth-child(1) span{
            display: inline-block;
            margin-left: 169px;
            width: 35%;
            height: 90%;
            border-radius: 16px;
            font-size: 23px;
            font-weight: 900;
            font-family: var(--Regular-Font-Family);
            color: white;
            text-align: center;
        }
        .BigOneGraph-4 .Graph-1 .TopBox div:nth-child(2){
            width: 100%;
            height: 50%;
            display: flex;
            flex-direction: row-reverse;
        }
        .BigOneGraph-4 .Graph-1 .TopBox div:nth-child(3){
            width: 100%;
            height: 50%;
        }
        .BigOneGraph-4 .Graph-1 .TopBox div:nth-child(2) span,
        .BigOneGraph-4 .Graph-1 .TopBox div:nth-child(3) span
        {
            display: inline-block;
            width: calc(100% / 3);
            height: 100%;
            border-radius: 16px;
            font-size: 26px;
            font-weight: 500;
            font-family: var(--Regular-Font-Family);
            color: var(--Font-Color);
            text-align: center;
        }
        .BigOneGraph-4 .Graph-1 .BottomBox{
            position: absolute;
            width: 417px;
            height: 100px;
            font-family: var(--Regular-Font-Family);
        }
        .BigOneGraph-4 .Graph-1 .BottomBox div:nth-child(3){
            width: 100%;
        }
        .BigOneGraph-4 .Graph-1 .BottomBox div:nth-child(3) span{
            display: inline-block;
            margin-left: 169px;
            width: 35%;
            height: 90%;
            border-radius: 16px;
            font-size: 23px;
            font-weight: 900;
            font-family: var(--Regular-Font-Family);
            color: white;
            text-align: center;
            background-color: #585a5b;
            margin-top: 12px;
        }
        .BigOneGraph-4 .Graph-1 .BottomBox div:nth-child(1){
            width: 100%;
            height: 50%;
        }
        .BigOneGraph-4 .Graph-1 .BottomBox div:nth-child(2){
            width: 100%;
            height: 25%;
            display: flex;
            flex-direction: row-reverse;
        }
        .BigOneGraph-4 .Graph-1 .BottomBox div:nth-child(1) span,
        .BigOneGraph-4 .Graph-1 .BottomBox div:nth-child(2) span
        {
            display: inline-block;
            width: calc(100% / 3);
            height: 100%;
            border-radius: 16px;
            font-size: 26px;
            font-weight: 500;
            font-family: var(--Regular-Font-Family);
            color: var(--Font-Color);
            text-align: center;
        }
    
        /* End BigOneGraph-4 */
    
    /* Start ManyBox-1 */
    .ManyBox-1{
        position: relative;
    }
    .ManyBox-1 .IntroBatrn{
        position: absolute;
        left: 16%;
    } 
    .ManyBox-1 .ManyBoxesBox{
        width: 835px;
        height: 328px;
        border-radius: 20px;
        font-family: var(--Regular-Font-Family);
    }

    .ManyBox-1 .ManyBoxesBox .LiftBox{
        width: 50%;
        height: 100%;
        display: inline-block;
        position: relative;
        top: -81px;
    }
    .ManyBox-1 .ManyBoxesBox .LiftBox .TopTitle{
        position: relative;
        top: 20px;
        left: 44px !important;
        display: inline-block;
        width: 86.5%;
        height: 25px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: white;
    }
    .ManyBox-1 .ManyBoxesBox .LiftBox .TopTitleBoxs{
        position: relative;
        top: 33px;
        left: 11%;
        width: 86%;
        height: 30px;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }
    .ManyBox-1 .ManyBoxesBox .LiftBox .TopTitleBoxs div{
        width: 50px;
        height: 20px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 4px;
        text-align: center;
        padding: 1px;
        font-size: 8px;
        font-weight: 900;
                    font-family: var(--Bold-Font-Family);
        color: white;
    }
    .ManyBox-1 .ManyBoxesBox .LiftBox .CenterBox{
        position: relative;
        top: 41px;
        left: 14px;
        margin: auto;
        width: 87%;
        height: 155px;
        border-radius: 20px;
        display: flex;
        justify-content: space-evenly;
    }
    .ManyBox-1 .ManyBoxesBox .LiftBox .CenterBox div{ 
        width: 80px;
        height: 50px;
        border: 1px solid white;
        padding: 3px;
        font-size: 14px;
        padding: 8px;
        padding-top: 10px;
        text-align: center;
        font-weight: 900;
                    font-family: var(--Bold-Font-Family);
        color: white;
        border: transparent;
    }

    .ManyBox-1 .ManyBoxesBox .LiftBox .BottumTitleBoxs{
        position: relative;
        top: 52px;
        left: 11%;
        width: 86%;
        height: 30px;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }
    .ManyBox-1 .ManyBoxesBox .LiftBox .BottumTitleBoxs div{
        width: 50px;
        height: 20px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 4px;
        text-align: center;
        padding: 1px;
        font-size: 8px;
        font-weight: 900;
                    font-family: var(--Bold-Font-Family);
        color: white;
    }
    .ManyBox-1 .ManyBoxesBox .LiftBox .BottumTitle{
        position: relative;
        top: 50px;
        left: 44px !important;
        display: inline-block;
        width: 86.5%;
        height: 25px;
        text-align: center;
        font-family: var(--Bold-Font-Family);
        font-weight: 900;
        color: white;
        font-size: 14px;
    }
    .ManyBox-1 .ManyBoxesBox .RightBox {
        width: 50%;
        height: 100%;
        display: inline-block;
        position: relative;
        top: -0px;
    }
    .ManyBox-1 .ManyBoxesBox .RightBox .BOX{
        position: relative;
        top: 52px;
        left: -5px;
        margin: auto;
        width: 79%;
        height: 240px;
        border-radius: 20px;
    }
    .ManyBox-1 .ManyBoxesBox .RightBox .BOX span{
        color: var(--BackGround-color-Blue-Bold);
        position: absolute;
        right: 5px;
        top: 5px;
        width: 97%;
        height: 100%;
        text-align: right;
        font-size: 10px;
        font-weight: 600;
                    font-family: var(--Bold-Font-Family);
        direction: rtl;
    }

    /* End ManyBox-1 */

        /* Start ManyBox-2 */

        .ManyBox-2{
            position: relative;
        }

        .ManyBox-2 .ManyBoxesBox{
            width: 835px;
            height: 327px;
            border-radius: 20px;
            font-family: var(--Regular-Font-Family);
        }
        .ManyBox-2 .ManyBoxesBox .LiftBox{
            width: 50%;
            height: 100%;
            display: inline-block;
            position: relative;
            top: -79px;
        }
        .ManyBox-2 .ManyBoxesBox .LiftBox .TopTitle{
            position: relative;
            top: 20px;
            left: 44px !important;
            display: inline-block;
            width: 86.5%;
            height: 25px;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            color: white;
        }
        .ManyBox-2 .ManyBoxesBox .LiftBox .TopTitleBoxs{
            position: relative;
            top: 33px;
            left: 11%;
            width: 86%;
            height: 30px;
            display: flex;
            justify-content: space-evenly;
            text-align: center;
        }
        .ManyBox-2 .ManyBoxesBox .LiftBox .TopTitleBoxs div{
            width: 50px;
            height: 20px;
            background-color: transparent;
            border: 1px solid white;
            border-radius: 4px;
            text-align: center;
            padding: 1px;
            font-size: 8px;
            font-weight: 900;
                        font-family: var(--Bold-Font-Family);
            color: white;
        }
        .ManyBox-2 .ManyBoxesBox .LiftBox .CenterBox{
            position: relative;
            top: 40px !important;
            left: 14px;
            margin: auto;
            width: 87%;
            height: 155px;
            border-radius: 20px;
            display: flex;
            justify-content: space-evenly;
        }
        .ManyBox-2 .ManyBoxesBox .LiftBox .CenterBox div{ 
            width: 80px;
            height: 50px;
            border: 1px solid white;
            padding: 3px;
            font-size: 14px;
            padding: 8px;
            padding-top: 10px;
            text-align: center;
            font-weight: 900;
                        font-family: var(--Bold-Font-Family);
            color: white;
            border: transparent;
        }
    
        .ManyBox-2 .ManyBoxesBox .LiftBox .BottumTitleBoxs{
            position: relative;
            top: 52px;
            left: 11%;
            width: 86%;
            height: 30px;
            display: flex;
            justify-content: space-evenly;
            text-align: center;
        }
        .ManyBox-2 .ManyBoxesBox .LiftBox .BottumTitleBoxs div{
            width: 50px;
            height: 20px;
            background-color: transparent;
            border: 1px solid white;
            border-radius: 4px;
            text-align: center;
            padding: 1px;
            font-size: 8px;
            font-weight: 900;
            font-family: var(--Bold-Font-Family);
            color: white;
        }
        .ManyBox-2 .ManyBoxesBox .LiftBox .BottumTitle{
            position: relative;
            top: 50px;
            left: 44px !important;
            display: inline-block;
            width: 86.5%;
            height: 25px;
            text-align: center;
            font-family: var(--Bold-Font-Family);
            font-weight: 900;
            color: white;
            font-size: 14px;
        }
        .ManyBox-2 .ManyBoxesBox .RightBox {
            width: 50%;
            height: 100%;
            display: inline-block;
            position: relative;
            top: -0px;
        }
    .ManyBox-2 .ManyBoxesBox .RightBox .BOX{
        position: relative;
        top: 52px;
        left: -5px;
        margin: auto;
        width: 79%;
        height: 240px;
        border-radius: 20px;
    }
    .ManyBox-2 .ManyBoxesBox .RightBox .BOX span{
        color: var(--BackGround-color-Blue-Bold);
        position: absolute;
        right: 5px;
        top: 5px;
        width: 97%;
        height: 100%;
        text-align: right;
        font-size: 10px;
        font-weight: 600;
                    font-family: var(--Bold-Font-Family);
        direction: rtl;
    }
    
        /* End ManyBox-2 */

            /* Start ManyBox-3 */

    .ManyBox-3{
        position: relative;
    }
    .ManyBox-3 .ManyBoxesBox{
        width: 835px;
        height: 328px;
        border-radius: 20px;
        font-family: var(--Regular-Font-Family);
    }
    .ManyBox-3 .ManyBoxesBox .LiftBox{
        width: 50%;
        height: 100%;
        display: inline-block;
        position: relative;
        top: -78px;
    }
    .ManyBox-3 .ManyBoxesBox .LiftBox .TopTitle{
        position: relative;
        top: 20px;
        left: 44px !important;
        display: inline-block;
        width: 86.5%;
        height: 25px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: white;
    }
    .ManyBox-3 .ManyBoxesBox .LiftBox .TopTitleBoxs{
        position: relative;
        top: 35px;
        left: 11%;
        width: 86%;
        height: 30px;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }
    .ManyBox-3 .ManyBoxesBox .LiftBox .TopTitleBoxs div{
        width: 50px;
        height: 20px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 4px;
        text-align: center;
        padding: 1px;
        font-size: 8px;
        font-weight: 900;
                    font-family: var(--Bold-Font-Family);
        color: white;
    }
    .ManyBox-3 .ManyBoxesBox .LiftBox .CenterBox{
        position: relative;
        top: 39px;
        left: 14px;
        margin: auto;
        width: 87%;
        height: 157px;
        border-radius: 20px;
        display: flex;
        justify-content: space-evenly;
    }
    .ManyBox-3 .ManyBoxesBox .LiftBox .CenterBox div{ 
        width: 80px;
        height: 50px;
        border: 1px solid white;
        padding: 3px;
        font-size: 14px;
        padding: 8px;
        padding-top: 10px;
        text-align: center;
        font-weight: 900;
                    font-family: var(--Bold-Font-Family);
        color: white;
        border: transparent;
    }

    .ManyBox-3 .ManyBoxesBox .LiftBox .BottumTitleBoxs{
        position: relative;
        top: 46px;
        left: 11%;
        width: 86%;
        height: 30px;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }
    .ManyBox-3 .ManyBoxesBox .LiftBox .BottumTitleBoxs div{
        width: 50px;
        height: 20px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 4px;
        text-align: center;
        padding: 1px;
        font-size: 8px;
        font-weight: 900;
                    font-family: var(--Bold-Font-Family);
        color: white;
    }
    .ManyBox-3 .ManyBoxesBox .LiftBox .BottumTitle{
        position: relative;
        top: 44px;
        left: 39px !important;
        display: inline-block;
        width: 86.5%;
        height: 25px;
        text-align: center;
        font-family: var(--Bold-Font-Family);
        font-weight: 900;
        color: white;
        font-size: 14px;
    }
    .ManyBox-3 .ManyBoxesBox .RightBox {
        width: 50%;
        height: 100%;
        display: inline-block;
        position: relative;
        top: -0px;
    }
    .ManyBox-3 .ManyBoxesBox .RightBox .BOX{
        position: relative;
        top: 52px;
        left: -5px;
        margin: auto;
        width: 79%;
        height: 240px;
        border-radius: 20px;
    }
    .ManyBox-3 .ManyBoxesBox .RightBox .BOX span{
        color: var(--BackGround-color-Blue-Bold);
        position: absolute;
        right: 5px;
        top: 5px;
        width: 97%;
        height: 100%;
        text-align: right;
        font-size: 10px;
        font-weight: 600;
        font-family: var(--Bold-Font-Family);
        direction: rtl;
    }

    /* End ManyBox-3 */
        /* Start BigOneGraph-5 */
        .BigOneGraph-5{
            position: relative;
        }
        .BigOneGraph-5 .IntroBatrn{
            position: absolute;
            left: 15%;
        } 
        .BigOneGraph-5 .Graph-1{
            position: absolute;
            top: 474px;
            left: 344px;
            width: 420px;
            height: 715.9px;
            border-radius: 12px;
        }
        .BigOneGraph-5 .TopTitle{
            position: absolute;
            top: 21%;
            width: 100%;
            height: 51px;
            display: flex;
            justify-content: center;
        } 
        .BigOneGraph-5 .TopTitle span{
            width: auto;
            height: auto;
            background-color: var(--BackGround-color-Gray);
            font-size: 23px;
            font-weight: 600;
            font-family: var(--Bold-Font-Family);
            color: white;
            padding: 8px;
            border-radius: 12px;
        } 
        .BigOneGraph-5 .BottomTitle{
            position: absolute;
            bottom: 69px;
            width: 100%;
            height: 51px;
            display: flex;
            justify-content: center;
        } 
        .BigOneGraph-5 .BottomTitle span{
            width: auto;
            height: auto;
            background-color: var(--BackGround-color-Gray-Sco);
            font-size: 23px;
            font-weight: 600;
            font-family: var(--Bold-Font-Family);
            color: white;
            padding: 8px;
            border-radius: 12px;
        } 
        .BigOneGraph-5 .Graph-1 .Graph-Top-1 {
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: space-between;
            padding: 0 55px; /* Total space on both sides */
        }
        
        .BigOneGraph-5 .Graph-1 .Graph-Top-1 .BigCanvas {
            width: 65px; /* Set the fixed width */
            position: relative;
            background-color: var(--BackGround-color-Gray);
            margin-top: auto;
            top: -1px;
        }
        .BigOneGraph-5 .Graph-1 .Graph-Bottom-1{ 
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: space-between;
            padding: 0 55px; /* Total space on both sides */
        }
        .BigOneGraph-5 .Graph-1 .Graph-Bottom-1 .BigCanvas {
            width: 65px; /* Set the fixed width */
            position: relative;
            background-color: var(--BackGround-color-Gray-Sco);
            margin-top: 1px;
        }
    
        .BigOneGraph-5 .Graph-1 .TopBox{
            position: absolute;
            top: -98px;
            width: 421px;
            height: 100px;
        }
        .BigOneGraph-5 .Graph-1 .TopBox div:nth-child(1){
            width: 100%;
        }
        .BigOneGraph-5 .Graph-1 .TopBox div:nth-child(1) span{
            display: inline-block;
            margin-left: 169px;
            width: 35%;
            height: 90%;
            border-radius: 16px;
            font-size: 23px;
            font-weight: 900;
                        font-family: var(--Bold-Font-Family);
            color: white;
            text-align: center;
            background-color: #585a5b;
        }
        .BigOneGraph-5 .Graph-1 .TopBox div:nth-child(2){
            width: 100%;
            height: 50%;
            display: flex;
            flex-direction: row-reverse;
        }
        .BigOneGraph-5 .Graph-1 .TopBox div:nth-child(3){
            width: 100%;
            height: 50%;
        }
        .BigOneGraph-5 .Graph-1 .TopBox div:nth-child(2) span,
        .BigOneGraph-5 .Graph-1 .TopBox div:nth-child(3) span
        {
            display: inline-block;
            width: calc(100% / 3);
            height: 100%;
            border-radius: 16px;
            font-size: 26px;
            font-weight: 500;
            color: var(--Font-Color);
            text-align: center;
            font-family: var(--Bold-Font-Family);
        }
        .BigOneGraph-5 .Graph-1 .BottomBox{
            position: absolute;
            width: 421px;
            height: 100px;
        }
        .BigOneGraph-5 .Graph-1 .BottomBox div:nth-child(3){
            width: 100%;
        }
        .BigOneGraph-5 .Graph-1 .BottomBox div:nth-child(3) span{
            display: inline-block;
            margin-left: 169px;
            width: 35%;
            height: 90%;
            border-radius: 16px;
            font-size: 23px;
            font-weight: 900;
                        font-family: var(--Bold-Font-Family);
            color: white;
            text-align: center;
            background-color: #585a5b;
            margin-top: 12px;
        }
        .BigOneGraph-5 .Graph-1 .BottomBox div:nth-child(1){
            width: 100%;
            height: 50%;
        }
        .BigOneGraph-5 .Graph-1 .BottomBox div:nth-child(2){
            width: 100%;
            height: 50%;
            display: flex;
            flex-direction: row-reverse;
        }
        .BigOneGraph-5 .Graph-1 .BottomBox div:nth-child(1) span,
        .BigOneGraph-5 .Graph-1 .BottomBox div:nth-child(2) span
        {
            display: inline-block;
            width: calc(100% / 3);
            height: 100%;
            border-radius: 16px;
            font-size: 26px;
            font-weight: 500;
                        font-family: var(--Bold-Font-Family);
            color: var(--Font-Color);
            text-align: center;
        }
                    
        /* End BigOneGraph-4 */
        /* Start ManyBox-4 */
        .ManyBox-4{
            position: relative;
        }
        .ManyBox-4 .IntroBatrn{
            position: absolute;
            left: 16%;
        } 
        .ManyBox-4 .ManyBoxesBox{
            width: 835px;
            height: 329px;
            border-radius: 20px;
            font-family: var(--Regular-Font-Family);
        }
        .ManyBox-4 .ManyBoxesBox .LiftBox{
            width: 50%;
            height: 100%;
            display: inline-block;
            position: relative;
            top: -86px;
        }
        .ManyBox-4 .ManyBoxesBox .LiftBox .TopTitle{
            position: relative;
            top: 20px;
            left: 44px !important;
            display: inline-block;
            width: 86.5%;
            height: 25px;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            color: white;
        }
        .ManyBox-4 .ManyBoxesBox .LiftBox .TopTitleBoxs{
            position: relative;
            top: 33px;
            left: 11%;
            width: 86%;
            height: 30px;
            display: flex;
            justify-content: space-evenly;
            text-align: center;
        }
        .ManyBox-4 .ManyBoxesBox .LiftBox .TopTitleBoxs div{
            width: 50px;
            height: 20px;
            background-color: transparent;
            border: 1px solid white;
            border-radius: 4px;
            text-align: center;
            padding: 1px;
            font-size: 8px;
            font-weight: 900;
                        font-family: var(--Bold-Font-Family);
            color: white;
        }
        .ManyBox-4 .ManyBoxesBox .LiftBox .CenterBox{
            position: relative;
            top: 47px;
            left: 14px;
            margin: auto;
            width: 87%;
            height: 155px;
            border-radius: 20px;
            display: flex;
            justify-content: space-evenly;
        }
        .ManyBox-4 .ManyBoxesBox .LiftBox .CenterBox div{ 
            width: 80px;
            height: 50px;
            border: 1px solid white;
            padding: 3px;
            font-size: 14px;
            padding: 8px;
            padding-top: 10px;
            text-align: center;
            font-weight: 900;
                        font-family: var(--Bold-Font-Family);
            color: white;
            border: transparent;
        }
    
        .ManyBox-4 .ManyBoxesBox .LiftBox .BottumTitleBoxs{
            position: relative;
            top: 52px;
            left: 11%;
            width: 86%;
            height: 30px;
            display: flex;
            justify-content: space-evenly;
            text-align: center;
        }
        .ManyBox-4 .ManyBoxesBox .LiftBox .BottumTitleBoxs div{
            width: 50px;
            height: 20px;
            background-color: transparent;
            border: 1px solid white;
            border-radius: 4px;
            text-align: center;
            padding: 1px;
            font-size: 8px;
            font-weight: 900;
                        font-family: var(--Bold-Font-Family);
            color: white;
        }
        .ManyBox-4 .ManyBoxesBox .LiftBox .BottumTitle{
            position: relative;
            top: 50px;
            left: 44px !important;
            display: inline-block;
            width: 86.5%;
            height: 25px;
            text-align: center;
            font-family: var(--Bold-Font-Family);
            font-weight: 900;
            color: white;
            font-size: 14px;
        }
        .ManyBox-4 .ManyBoxesBox .RightBox {
            width: 50%;
            height: 100%;
            display: inline-block;
            position: relative;
            top: -0px;
        }
        .ManyBox-4 .ManyBoxesBox .RightBox .BOX{
            position: relative;
            top: 52px;
            left: -5px;
            margin: auto;
            width: 79%;
            height: 240px;
            border-radius: 20px;
        }
        .ManyBox-4 .ManyBoxesBox .RightBox .BOX span{
            color: var(--BackGround-color-Gray);
            position: absolute;
            right: 5px;
            top: 5px;
            width: 97%;
            height: 100%;
            text-align: right;
            font-size: 10px;
            font-weight: 600;
                        font-family: var(--Bold-Font-Family);
            direction: rtl;
        }
    
        /* End ManyBox-4 */

            /* Start ManyBox-5 */

    .ManyBox-5{
        position: relative;
    }
    .ManyBox-5 .ManyBoxesBox{
        width: 835px;
        height: 330px;
        border-radius: 20px;
        font-family: var(--Regular-Font-Family);
    }
    .ManyBox-5 .ManyBoxesBox .LiftBox{
        width: 50%;
        height: 100%;
        display: inline-block;
        position: relative;
        top: -85px;
    }
    .ManyBox-5 .ManyBoxesBox .LiftBox .TopTitle{
        position: relative;
        top: 20px;
        left: 6px !important;
        display: inline-block;
        width: 102.5%;
        height: 25px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: white;
    }
    .ManyBox-5 .ManyBoxesBox .LiftBox .TopTitleBoxs{
        position: relative;
        top: 33px;
        left: 11%;
        width: 86%;
        height: 30px;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }
    .ManyBox-5 .ManyBoxesBox .LiftBox .TopTitleBoxs div{
        width: 50px;
        height: 20px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 4px;
        text-align: center;
        padding: 1px;
        font-size: 8px;
        font-weight: 900;
                    font-family: var(--Bold-Font-Family);
        color: white;
    }
    .ManyBox-5 .ManyBoxesBox .LiftBox .CenterBox{
        position: relative;
        top: 44px;
        left: 14px;
        margin: auto;
        width: 87%;
        height: 155px;
        border-radius: 20px;
        display: flex;
        justify-content: space-evenly;
    }
    .ManyBox-5 .ManyBoxesBox .LiftBox .CenterBox div{ 
        width: 80px;
        height: 50px;
        border: 1px solid white;
        padding: 3px;
        font-size: 14px;
        padding: 8px;
        padding-top: 10px;
        text-align: center;
        font-weight: 900;
                    font-family: var(--Bold-Font-Family);
        color: white;
        border: transparent;
    }

    .ManyBox-5 .ManyBoxesBox .LiftBox .BottumTitleBoxs{
        position: relative;
        top: 52px;
        left: 11%;
        width: 86%;
        height: 30px;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }
    .ManyBox-5 .ManyBoxesBox .LiftBox .BottumTitleBoxs div{
        width: 50px;
        height: 20px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 4px;
        text-align: center;
        padding: 1px;
        font-size: 8px;
        font-weight: 900;
                    font-family: var(--Bold-Font-Family);
        color: white;
    }
    .ManyBox-5 .ManyBoxesBox .LiftBox .BottumTitle{
        position: relative;
        top: 50px;
        left: 44px !important;
        display: inline-block;
        width: 86.5%;
        height: 25px;
        text-align: center;
        font-family: var(--Bold-Font-Family);
        font-weight: 900;
        color: white;
        font-size: 14px;
    }
    .ManyBox-5 .ManyBoxesBox .RightBox {
        width: 50%;
        height: 100%;
        display: inline-block;
        position: relative;
        top: -0px;
    }
    .ManyBox-5 .ManyBoxesBox .RightBox .BOX{
        position: relative;
        top: 52px;
        left: -5px;
        margin: auto;
        width: 79%;
        height: 240px;
        border-radius: 20px;
    }
    .ManyBox-5 .ManyBoxesBox .RightBox .BOX span{
        color: var(--BackGround-color-Gray);
        position: absolute;
        right: 5px;
        top: 5px;
        width: 97%;
        height: 100%;
        text-align: right;
        font-size: 10px;
        font-weight: 600;
                    font-family: var(--Bold-Font-Family);
        direction: rtl;
    }

    /* End ManyBox-5 */
        /* Start ManyBox-6 */

        .ManyBox-6{
            position: relative;
        }
        .ManyBox-6 .ManyBoxesBox{
            width: 835px;
            height: 329px;
            border-radius: 20px;
            font-family: var(--Regular-Font-Family);
        }
        .ManyBox-6 .ManyBoxesBox .LiftBox{
            width: 50%;
            height: 100%;
            display: inline-block;
            position: relative;
            top: -73px;
        }
        .ManyBox-6 .ManyBoxesBox .LiftBox .TopTitle{
            position: relative;
            top: 8px;
            left: 0 !important;
            display: inline-block;
            width: 110%;
            height: 25px;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            color: white;
        }
        .ManyBox-6 .ManyBoxesBox .LiftBox .TopTitleBoxs{
            position: relative;
            top: 19px;
            left: 11%;
            width: 86%;
            height: 30px;
            display: flex;
            justify-content: space-evenly;
            text-align: center;
        }
        .ManyBox-6 .ManyBoxesBox .LiftBox .TopTitleBoxs div{
            width: 50px;
            height: 20px;
            background-color: transparent;
            border: 1px solid white;
            border-radius: 4px;
            text-align: center;
            padding: 1px;
            font-size: 8px;
            font-weight: 900;
                        font-family: var(--Bold-Font-Family);
            color: white;
        }
        .ManyBox-6 .ManyBoxesBox .LiftBox .CenterBox{
            position: relative;
            top: 28px;
            left: 14px;
            margin: auto;
            width: 87%;
            height: 151px;
            border-radius: 20px;
            display: flex;
            justify-content: space-evenly;
        }
        .ManyBox-6 .ManyBoxesBox .LiftBox .CenterBox div{ 
            width: 80px;
            height: 50px;
            border: 1px solid white;
            padding: 3px;
            font-size: 14px;
            padding: 8px;
            padding-top: 10px;
            text-align: center;
            font-weight: 900;
                        font-family: var(--Bold-Font-Family);
            color: white;
            border: transparent;
        }
    
        .ManyBox-6 .ManyBoxesBox .LiftBox .BottumTitleBoxs{
            position: relative;
            top: 43px;
            left: 11%;
            width: 86%;
            height: 30px;
            display: flex;
            justify-content: space-evenly;
            text-align: center;
        }
        .ManyBox-6 .ManyBoxesBox .LiftBox .BottumTitleBoxs div{
            width: 50px;
            height: 20px;
            background-color: transparent;
            border: 1px solid white;
            border-radius: 4px;
            text-align: center;
            padding: 1px;
            font-size: 8px;
            font-weight: 900;
                        font-family: var(--Bold-Font-Family);
            color: white;
        }
        .ManyBox-6 .ManyBoxesBox .LiftBox .BottumTitle{
            position: relative;
            top: 39px;
            left: 44px !important;
            display: inline-block;
            width: 86.5%;
            height: 25px;
            text-align: center;
            font-family: var(--Bold-Font-Family);
            font-weight: 900;
            color: white;
            font-size: 14px;
        }
        .ManyBox-6 .ManyBoxesBox .RightBox {
            width: 50%;
            height: 100%;
            display: inline-block;
            position: relative;
            top: -0px;
        }
        .ManyBox-6 .ManyBoxesBox .RightBox .BOX{
            position: relative;
            top: 52px;
            left: -5px;
            margin: auto;
            width: 79%;
            height: 240px;
            border-radius: 20px;
        }
        .ManyBox-6 .ManyBoxesBox .RightBox .BOX span{
            color: var(--BackGround-color-Gray);
            position: absolute;
            right: 5px;
            top: 5px;
            width: 97%;
            height: 100%;
            text-align: right;
            font-size: 10px;
            font-weight: 600;
            font-family: var(--Bold-Font-Family);
            direction: rtl;
        }
        /* End ManyBox-6 */
        /* Start BigOneGraph-6 */
    .BigOneGraph-6{
        position: relative;
    }
    .BigOneGraph-6 .IntroBatrn{
        position: absolute;
        left: 17%;
    } 
    .BigOneGraph-6 .Graph-1{
        position: absolute;
        top: 472px;
        left: 346px;
        width: 420px;
        height: 718px;    
        border-radius: 12px;
    }
    .BigOneGraph-6 .TopTitle{
        position: absolute;
        top: 21%;
        width: 100%;
        height: 51px;
        display: flex;
        justify-content: center;
    } 
    .BigOneGraph-6 .TopTitle span{
        width: auto;
        height: auto;
        background-color: var(--BackGround-color-Red);
        font-size: 23px;
        font-weight: 600;
        font-family: var(--Bold-Font-Family);
        color: white;
        padding: 8px;
        border-radius: 12px;
    } 
    .BigOneGraph-6 .BottomTitle{
        position: absolute;
        bottom: 69px;
        width: 100%;
        height: 51px;
        display: flex;
        justify-content: center;
    } 
    .BigOneGraph-6 .BottomTitle span{
        width: auto;
        height: auto;
        background-color: var(--BackGround-color-Red-Sco);
        font-size: 23px;
        font-weight: 600;
        font-family: var(--Bold-Font-Family);
        color: white;
        padding: 8px;
        border-radius: 12px;
    } 
    .BigOneGraph-6 .Graph-1 .Graph-Top-1 {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: space-between;
        padding: 0 55px; /* Total space on both sides */
    }
    
    .BigOneGraph-6 .Graph-1 .Graph-Top-1 .BigCanvas {
        width: 65px; /* Set the fixed width */
        position: relative;
        background-color: var(--BackGround-color-Red);
        margin-top: auto;
        top: -1px;
    }
    .BigOneGraph-6 .Graph-1 .Graph-Bottom-1{ 
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: space-between;
        padding: 0 55px; /* Total space on both sides */
    }
    .BigOneGraph-6 .Graph-1 .Graph-Bottom-1 .BigCanvas {
        width: 65px; /* Set the fixed width */
        position: relative;
        background-color: var(--BackGround-color-Red-Sco);
        margin-top: 1px;
    }

    .BigOneGraph-6 .Graph-1 .TopBox{
        position: absolute;
        top: -110px;
        width: 421px;
        height: 100px;
    }
    .BigOneGraph-6 .Graph-1 .TopBox div:nth-child(1){
        width: 100%;
    }
    .BigOneGraph-6 .Graph-1 .TopBox div:nth-child(1) span{
        display: inline-block;
        margin-left: 169px;
        width: 35%;
        height: 90%;
        border-radius: 16px;
        font-size: 23px;
        font-weight: 900;
                    font-family: var(--Bold-Font-Family);
        color: white;
        text-align: center;
        background-color: #585a5b;
    }
    .BigOneGraph-6 .Graph-1 .TopBox div:nth-child(2){
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: row-reverse;
    }
    .BigOneGraph-6 .Graph-1 .TopBox div:nth-child(3){
        width: 100%;
        height: 50%;
    }
    .BigOneGraph-6 .Graph-1 .TopBox div:nth-child(2) span,
    .BigOneGraph-6 .Graph-1 .TopBox div:nth-child(3) span
    {
        display: inline-block;
        width: calc(100% / 3);
        height: 100%;
        border-radius: 16px;
        font-size: 26px;
        font-weight: 500;
                    font-family: var(--Bold-Font-Family);
        color: var(--Font-Color);
        text-align: center;
    }
    .BigOneGraph-6 .Graph-1 .BottomBox{
        position: absolute;
        width: 421px;
        height: 100px;
    }
    .BigOneGraph-6 .Graph-1 .BottomBox div:nth-child(3){
        width: 100%;
    }
    .BigOneGraph-6 .Graph-1 .BottomBox div:nth-child(3) span{
        display: inline-block;
        margin-left: 169px;
        width: 35%;
        height: 90%;
        border-radius: 16px;
        font-size: 23px;
        font-weight: 900;
                    font-family: var(--Bold-Font-Family);
        color: white;
        text-align: center;
        background-color: #585a5b;
        margin-top: 12px;
    }
    .BigOneGraph-6 .Graph-1 .BottomBox div:nth-child(1){
        width: 100%;
        height: 50%;
    }
    .BigOneGraph-6 .Graph-1 .BottomBox div:nth-child(2){
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: row-reverse;
    }
    .BigOneGraph-6 .Graph-1 .BottomBox div:nth-child(1) span,
    .BigOneGraph-6 .Graph-1 .BottomBox div:nth-child(2) span
    {
        display: inline-block;
        width: calc(100% / 3);
        height: 100%;
        border-radius: 16px;
        font-size: 26px;
        font-weight: 500;
                    font-family: var(--Bold-Font-Family);
        color: var(--Font-Color);
        text-align: center;
    }

    /* End BigOneGraph-5 */
    /* END REPORT 4 */
