
    /* START REPORT 1 */

    /* Start Cover */

    .PDF .BackGroundImge .Cover {
        position: relative;
    }
    .PDF .BackGroundImge .Cover .BigCover{
        position: absolute;
        bottom: 270px;
        width: 100%;
        height: 50%;    
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .PDF .BackGroundImge .Cover .BigCover .Tilte {
        background: var(--BackGround-color-Blue-Bold);
        width: 750px;
        height: 70px;
        border-radius: 10px;
        margin: 0 auto 0 auto; 
        display: flex; 
        justify-content: center; 
        align-items: center;
        padding: 45px;
        font-family: var(--Bold-Font-Family);
    }
    
    .PDF .BackGroundImge .Cover .BigCover .Tilte span {
        color: white;
        font-size: 49px;
        font-weight: bolder;
        position: relative;
        top: -7px;
        width: 100%;
        text-align: center;
    }
    
    .PDF .BackGroundImge .Cover .BigCover .DivLogo {
        width: 750px;
        height: auto;
        margin: 0 auto 0 auto;
    }
    .PDF .BackGroundImge .Cover .BigCover .DivLogo .Logo {
        width: 100%;
    }
    
    .PDF .BackGroundImge .Cover .BigCover .div {
        width: 750px;
        height: 170px;
        margin: 0 auto 0 auto; 
        text-align: center;
        line-height: 85px;
        position: relative;
        top: 45px;
        font-family: var(--Regular-Font-Family);
    }
    
    .PDF .BackGroundImge .Cover .BigCover .div span{
        font-size: 40px;
        font-weight: bolder;
        display: inline-block;
    }
    .PDF .BackGroundImge .Cover .BigCover .PersonDetils {
        width: 750px;
        height: auto;
        margin: 0 auto 0 auto; 
        text-align: center;
        position: absolute;
        bottom: -100px;
    }
    .PDF .BackGroundImge .Cover .BigCover .PersonDetils span {
        color: var(--Font-Color);
        display: block;
        font-size: 35px;
        font-weight: 800;
    }
    /* End Cover */
    /* Start Intro */
    .PDF .BackGroundImge .Intro{
        position: relative;
    }
    .Intro .IntroBatrn{
        position: absolute;
        left: 42%;
    }
    .PDF .BackGroundImge .Intro .IntroText{
        position: absolute;
        top: 281px;
        right: 60px;
        width: 85%;
    }
    .PDF .BackGroundImge .Intro .IntroText .Text{
        font-size: 28px;
        font-family: var(--Regular-Font-Family);
        text-align: right;
        color: var(--Font-Color);
        direction: rtl;
    }
    .PDF .BackGroundImge .Intro .IntroTowBox{
        width: 100%;
        height: 400px;
        position: absolute;
        bottom: 227px;
    }
    .PDF .BackGroundImge .Intro .IntroTowBox .TowBoxContaner{
        width: 745px;
        height: 100%;
        margin: auto;
        display: flex;
        font-family: var(--Bold-Font-Family);
    }
    .PDF .BackGroundImge .Intro .IntroTowBox .TowBoxContaner .BoxOne{
        width: 49%;
        margin-right: 1%;
        border-radius: 18px;
        position: relative;
        background-color: var(--BackGround-color-Blue-Sco);
    }
    .PDF .BackGroundImge .Intro .IntroTowBox .TowBoxContaner .BoxOne .NastedBox{
        width: 100%;
        height: 80%;
        background: aqua;
        position: absolute;
        bottom: 0;
        border-bottom-right-radius: 18px;
        border-bottom-left-radius: 18px;
        border-top-right-radius: 28px;
        border-top-left-radius: 28px;
        background-color: #347797;
        padding-top: 30px;
    }
    .PDF .BackGroundImge .Intro .IntroTowBox .TowBoxContaner .BoxOne  h2{
        color: white;
        text-align: center;
        margin-top: 12px;
    }
    .PDF .BackGroundImge .Intro .IntroTowBox .TowBoxContaner .BoxOne .NastedBox div{
        width: 100%;
        height: calc(80% / 4);
        text-align: center;
    }
    .PDF .BackGroundImge .Intro .IntroTowBox .TowBoxContaner .BoxOne .NastedBox div span{
        color: white;
        font-size: 26px;
        padding: 19px;
        border-bottom: 2px solid;
    }
    .PDF .BackGroundImge .Intro .IntroTowBox .TowBoxContaner .BoxTow{
        width: 49%;
        margin-left: 1%;
        border-radius: 18px;
        position: relative;
        background-color: var(--BackGround-color-Blue-Sco);
    }
    .PDF .BackGroundImge .Intro .IntroTowBox .TowBoxContaner .BoxTow .NastedBox{
        width: 100%;
        height: 80%;
        background: aqua;
        position: absolute;
        bottom: 0;
        border-bottom-right-radius: 18px;
        border-bottom-left-radius: 18px;
        border-top-right-radius: 28px;
        border-top-left-radius: 28px;
        background-color: #2f6588;
        padding-top: 30px;
    }
    .PDF .BackGroundImge .Intro .IntroTowBox .TowBoxContaner .BoxTow  h2{
        color: white;
        text-align: center;
        margin-top: 12px;
    }
    .PDF .BackGroundImge .Intro .IntroTowBox .TowBoxContaner .BoxTow .NastedBox div{
        width: 100%;
        height: calc(80% / 4);
        text-align: center;
    }
    .PDF .BackGroundImge .Intro .IntroTowBox .TowBoxContaner .BoxTow .NastedBox div span{
        color: white;
        font-size: 26px;
        padding: 19px;
        border-bottom: 2px solid;
    }
    /* End Intro */
    /* start catalogue */
    .catalogue{
        position: relative;
        width: 1100px;
    }
    .catalogue .IntroBatrn{
        position: absolute;
        left: 37%;
        top: 9.4%;
    }
    .catalogue .cataloguePhrase{
        width: 80%;
        height: 75%;
        position: absolute;
        top: 11%;
        right: 50px;
        font-family: var(--Regular-Font-Family);
    }
    .catalogue .cataloguePhrase .Phrase{
        width: 105%;
        height: 40px;
        position: relative;
        right: 8px;
        top: 60px;
        float: right;
        display: flex;
        margin-bottom: 2px;
    }
    .catalogue .cataloguePhrase .Phrase .Number{
        font-size: 26px;
        width: 37px;
        font-weight: 500;
        position: absolute;
        right: 1px;
        top: -7px;
        color: white;
        text-align: center;
    }
    .catalogue .cataloguePhrase .Phrase .Text{
        font-size: 27px;
        position: absolute;
        right: 55px;
        top: 1px;
        color: var(--Font-Color);
        font-family: var(--font-family);
        direction: rtl;
    }
    .catalogue  .LastText{
        width: 80%;
        position: absolute;
        bottom: 150px;
        right: 42px;
        font-size: 23px;
        font-weight: 600;
        font-family: var(--Regular-Font-Family);
        text-align: right;
        color: var(--Font-Color);
        direction: rtl;
    }
    /* // */
    .GenralBigText{
        position: relative;
    }
    .GenralBigText .IntroBatrn{
        position: absolute;
        left: 39%;
    }
    .GenralBigText .BigText {
        position: absolute;
        top: 18%;
        right: 50px;
        font-family: var(--Regular-Font-Family);
        color: var(--Font-Color);
        text-align: right;
        font-size: 25px;
        direction: rtl;
        width: 90%;
        height: 70%;
    }
    /* End catalogue */
    /* Start ManyGraphs-1 */
    .ManyGraphs-1{
        position: relative;
        width: 1104px;
        height: 1563px;
    }
    .ManyGraphs-1 .IntroBatrn{
        position: absolute;
        left: 34.5%;
    }
    .ManyGraphs-1 .Graph-1{
        position: absolute;
        top: 391px;
        left: 295px;
        width: 205.5px;
        height: 341px;
        border-radius: 10px;
    }
    .ManyGraphs-1 .Graph-1 .TopBox{
        position: absolute;
        top: -103px;
        width: 200px;
        height: 100px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-family: var(--Regular-Font-Family);
    }
    .ManyGraphs-1 .Graph-1 .TopBox div:nth-child(1){
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        width: 47%;
        height: 39%;
        background-color: var(--BackGround-color-Gray);
        border-radius: 16px;
    }
    .ManyGraphs-1 .Graph-1 .TopBox div:nth-child(1) span{
        font-size: 18px;
        padding-top: 3px;
        font-weight: 900;
        color: white;
        direction: rtl;
        height: 100%;
    }
    .ManyGraphs-1 .Graph-1 .TopBox div:nth-child(2),
    .ManyGraphs-1 .Graph-1 .TopBox div:nth-child(3){
        width: 180px;
    }
    .ManyGraphs-1 .Graph-1 .TopBox div:nth-child(2) span,
    .ManyGraphs-1 .Graph-1 .TopBox div:nth-child(3) span
    {
        width: calc(100% / 3 - 20px);
        display: inline-block;
        margin: 0 10px 0 10px;
        font-size: 12px;
        font-weight: bolder;
        font-family: var(--font-family);
        color: var(--Font-Color);
        text-align: center;
    }
    .ManyGraphs-1 .Graph-1 .BottomBox{
        position: absolute;
        width: 200px;
        height: 100px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-family: var(--Regular-Font-Family);
    }
    .ManyGraphs-1 .Graph-1 .BottomBox div:nth-child(3){
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        width: 47%;
        height: 39%;
        background-color: var(--BackGround-color-Gray-Sco);
        border-radius: 16px;
    }
    .ManyGraphs-1 .Graph-1 .BottomBox div:nth-child(3) span{
        font-size: 18px;
        padding-top: 3px;
        font-weight: 900;
        color: white;
        direction: rtl;
        height: 100%;
    }
    .ManyGraphs-1 .Graph-1 .BottomBox div:nth-child(1),
        .ManyGraphs-1 .Graph-1 .BottomBox div:nth-child(2){
            width: 180px;
    }
    .ManyGraphs-1 .Graph-1 .BottomBox div:nth-child(1) span,
    .ManyGraphs-1 .Graph-1 .BottomBox div:nth-child(2) span
    {
        width: calc(100% / 3 - 20px);
        display: inline-block;
        margin: 0 10px 0 10px;
        font-size: 12px;
        font-weight: bolder;
        color: var(--Font-Color);
        text-align: center;
    }

    .ManyGraphs-1 .Graph-2 .TopBox{
        position: absolute;
        top: -103px;
        width: 200px;
        height: 100px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-family: var(--Regular-Font-Family);
    }
    .ManyGraphs-1 .Graph-2 .TopBox div:nth-child(1){
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        width: 47%;
        height: 39%;
        background-color: var(--BackGround-color-Blue-Bold);
        border-radius: 16px;
    }
    .ManyGraphs-1 .Graph-2 .TopBox div:nth-child(1) span{
        font-size: 18px;
        padding-top: 3px;
        font-weight: 900;
        color: white;
        direction: rtl;
        height: 100%;
    }
    .ManyGraphs-1 .Graph-2 .TopBox div:nth-child(2),
    .ManyGraphs-1 .Graph-2 .TopBox div:nth-child(3){
        width: 180px;
    }
    .ManyGraphs-1 .Graph-2 .TopBox div:nth-child(2) span,
    .ManyGraphs-1 .Graph-2 .TopBox div:nth-child(3) span
    {
        width: calc(100% / 3 - 20px);
        display: inline-block;
        margin: 0 10px 0 10px;
        font-size: 12px;
        font-weight: bolder;
        font-family: var(--font-family);
        color: var(--Font-Color);
        text-align: center;
    }
    .ManyGraphs-1 .Graph-2 .BottomBox{
        position: absolute;
        width: 200px;
        height: 100px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-family: var(--Regular-Font-Family);
    }
    .ManyGraphs-1 .Graph-2 .BottomBox div:nth-child(3){
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        width: 47%;
        height: 39%;
        background-color: var(--BackGround-color-Blue-Sco);
        border-radius: 16px;
    }
    .ManyGraphs-1 .Graph-2 .BottomBox div:nth-child(3) span{
        font-size: 18px;
        padding-top: 3px;
        font-weight: 900;
        color: white;
        direction: rtl;
        height: 100%;
    }
    .ManyGraphs-1 .Graph-2 .BottomBox div:nth-child(2),
    .ManyGraphs-1 .Graph-2 .BottomBox div:nth-child(1){
        width: 180px;
    }
    .ManyGraphs-1 .Graph-2 .BottomBox div:nth-child(1) span,
    .ManyGraphs-1 .Graph-2 .BottomBox div:nth-child(2) span
    {
        width: calc(100% / 3 - 20px);
        display: inline-block;
        margin: 0 10px 0 10px;
        font-size: 12px;
        font-weight: bolder;
        font-family: var(--font-family);
        color: var(--Font-Color);
        text-align: center;
    }
    .ManyGraphs-1 .Graph-3 .TopBox{
        position: absolute;
        top: -103px;
        width: 200px;
        height: 100px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-family: var(--Regular-Font-Family);
    }
    .ManyGraphs-1 .Graph-3 .TopBox div:nth-child(1){
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        width: 47%;
        height: 39%;
        background-color: var(--BackGround-color-Green);
        border-radius: 16px;
    }
    .ManyGraphs-1 .Graph-3 .TopBox div:nth-child(1) span{
        font-size: 18px;
        padding-top: 3px;
        font-weight: 900;
        color: white;
        direction: rtl;
        height: 100%;
    }
    .ManyGraphs-1 .Graph-3 .TopBox div:nth-child(2),
    .ManyGraphs-1 .Graph-3 .TopBox div:nth-child(3){
        width: 180px;
    }
    .ManyGraphs-1 .Graph-3 .TopBox div:nth-child(2) span,
    .ManyGraphs-1 .Graph-3 .TopBox div:nth-child(3) span
    {
        width: calc(100% / 3 - 20px);
        display: inline-block;
        margin: 0 10px 0 10px;
        font-size: 12px;
        font-weight: bolder;
        font-family: var(--font-family);
        color: var(--Font-Color);
        text-align: center;
    }
    .ManyGraphs-1 .Graph-3 .BottomBox{
        position: absolute;
        width: 200px;
        height: 100px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-family: var(--Regular-Font-Family);
    }
    .ManyGraphs-1 .Graph-3 .BottomBox div:nth-child(3){
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        width: 47%;
        height: 39%;
        background-color: var(--BackGround-color-Green-Sco);
        border-radius: 16px;
    }
    .ManyGraphs-1 .Graph-3 .BottomBox div:nth-child(3) span{
        font-size: 18px;
        padding-top: 3px;
        font-weight: 900;
        color: white;
        direction: rtl;
        height: 100%;
    }
    .ManyGraphs-1 .Graph-3 .BottomBox div:nth-child(2),
    .ManyGraphs-1 .Graph-3 .BottomBox div:nth-child(1){
        width: 180px;
    }
    .ManyGraphs-1 .Graph-3 .BottomBox div:nth-child(1) span,
    .ManyGraphs-1 .Graph-3 .BottomBox div:nth-child(2) span
    {
        width: calc(100% / 3 - 20px);
        display: inline-block;
        margin: 0 10px 0 10px;
        font-size: 12px;
        font-weight: bolder;
        font-family: var(--font-family);
        color: var(--Font-Color);
        text-align: center;
    }
    .ManyGraphs-1 .Graph-4 .TopBox{
        position: absolute;
        top: -103px;
        width: 200px;
        height: 100px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-family: var(--Regular-Font-Family);
    }
    .ManyGraphs-1 .Graph-4 .TopBox div:nth-child(1){
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        width: 47%;
        height: 39%;
        background-color: var(--BackGround-color-Red);
        border-radius: 16px;
    }
    .ManyGraphs-1 .Graph-4 .TopBox div:nth-child(1) span{
        font-size: 18px;
        padding-top: 3px;
        font-weight: 900;
        color: white;
        direction: rtl;
        height: 100%;
    }
    .ManyGraphs-1 .Graph-4 .TopBox div:nth-child(2),
    .ManyGraphs-1 .Graph-4 .TopBox div:nth-child(3){
        width: 180px;
    }
    .ManyGraphs-1 .Graph-4 .TopBox div:nth-child(2) span,
    .ManyGraphs-1 .Graph-4 .TopBox div:nth-child(3) span
    {
        width: calc(100% / 3 - 20px);
        display: inline-block;
        margin: 0 10px 0 10px;
        font-size: 12px;
        font-weight: bolder;
        font-family: var(--font-family);
        color: var(--Font-Color);
        text-align: center;
    }
    .ManyGraphs-1 .Graph-4 .BottomBox{
        position: absolute;
        width: 200px;
        height: 100px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-family: var(--Regular-Font-Family);
    }
    .ManyGraphs-1 .Graph-4 .BottomBox div:nth-child(3){
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        width: 47%;
        height: 39%;
        background-color: var(--BackGround-color-Red-Sco);
        border-radius: 16px;
    }
    .ManyGraphs-1 .Graph-4 .BottomBox div:nth-child(3) span{
        font-size: 18px;
        padding-top: 3px;
        font-weight: 900;
        color: white;
        direction: rtl;
        height: 100%;
    }
    .ManyGraphs-1 .Graph-4 .BottomBox div:nth-child(1){
        width: 180px;
    }
    .ManyGraphs-1 .Graph-4 .BottomBox div:nth-child(1) span,
    .ManyGraphs-1 .Graph-4 .BottomBox div:nth-child(2) span
    {
        width: calc(100% / 3 - 20px);
        display: inline-block;
        margin: 0 10px 0 10px;
        font-size: 12px;
        font-weight: bolder;
        font-family: var(--font-family);
        color: var(--Font-Color);
        text-align: center;
    }
    
    .ManyGraphs-1 .Graph-1 .Graph-Top-1,
    .ManyGraphs-1 .Graph-1 .Graph-Bottom-1 {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: space-between;
        padding: 0 20px; /* Total space on both sides */
    }
    
    .ManyGraphs-1 .Graph-1 .Graph-Top-1 .Canvas{
        width: 120px; /* Set the increased width */
        position: relative;
        background-color: var(--BackGround-color-Gray);
    }
    .ManyGraphs-1 .Graph-1 .Graph-Bottom-1 .Canvas{
        width: 120px; /* Set the increased width */
        position: relative;
        background-color: var(--BackGround-color-Gray-Sco);
    }
    
    .ManyGraphs-1 .Graph-1 .Graph-Top-1 .Canvas{
        margin-top: auto; 
        margin-left: 10px;
        margin-right: 10px;
    }
    .ManyGraphs-1 .Graph-1 .Graph-Bottom-1 .Canvas{
        margin: 1px 10px; 
    }
    
    .ManyGraphs-1 .Graph-2 {
        position: absolute;
        top: 391px;
        left: 648px;
        width: 205.5px;
        height: 343px;
        border-radius: 10px;
    }
    
    .ManyGraphs-1 .Graph-2 .Graph-Top-1,
    .ManyGraphs-1 .Graph-2 .Graph-Bottom-1 {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: space-between;
        padding: 0 20px; /* Total space on both sides */
    }
    
    .ManyGraphs-1 .Graph-2 .Graph-Top-1 .Canvas{
        width: 100px; /* Set the fixed width */
        position: relative;
        background-color: var(--BackGround-color-Blue);
    }
    .ManyGraphs-1 .Graph-2 .Graph-Bottom-1 .Canvas {
        width: 100px; /* Set the fixed width */
        position: relative;
        background-color: var(--BackGround-color-Blue-Sco);
    }
    
    .ManyGraphs-1 .Graph-2 .Graph-Top-1 .Canvas{
        margin-top: auto; 
        margin-left: 10px;
        margin-right: 10px;
    }
    .ManyGraphs-1 .Graph-2 .Graph-Bottom-1 .Canvas{
        margin: 1px 10px; /* To push the first Canvas to the top */
    }
    .ManyGraphs-1 .Graph-3{
        position: absolute;
        top: 943px;
        left: 295px;
        width: 205.5px;
        height: 342px;
        border-radius: 10px;
    }
    .ManyGraphs-1 .Graph-3 .Graph-Top-1,
    .ManyGraphs-1 .Graph-3 .Graph-Bottom-1 {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: space-between;
        padding: 0 20px; /* Total space on both sides */
    }
    
    .ManyGraphs-1 .Graph-3 .Graph-Top-1 .Canvas{
        width: 100px; /* Set the fixed width */
        position: relative;
        background-color: var(--BackGround-color-Green);
    }
    .ManyGraphs-1 .Graph-3 .Graph-Bottom-1 .Canvas {
        width: 100px; /* Set the fixed width */
        position: relative;
        background-color: var(--BackGround-color-Green-Sco);
    }
    
    .ManyGraphs-1 .Graph-3 .Graph-Top-1 .Canvas{
        margin-top: auto; /* To push the first Canvas to the top */
        margin-left: 10px;
        margin-right: 10px;
    }
    .ManyGraphs-1 .Graph-3 .Graph-Bottom-1 .Canvas{
        margin: 1px 10px; /* To push the first Canvas to the top */
    }
    .ManyGraphs-1 .Graph-4{
        position: absolute;
        top: 943px;
        left: 648px;
        width: 205.5px;
        height: 343px;
        border-radius: 10px;
    }
    .ManyGraphs-1 .Graph-4 .Graph-Top-1,
    .ManyGraphs-1 .Graph-4 .Graph-Bottom-1 {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: space-between;
        padding: 0 20px; /* Total space on both sides */
    }
    
    .ManyGraphs-1 .Graph-4 .Graph-Top-1 .Canvas{
        width: 100px; /* Set the fixed width */
        position: relative;
        background-color: var(--BackGround-color-Red);
    }
    .ManyGraphs-1 .Graph-4 .Graph-Bottom-1 .Canvas {
        width: 100px; /* Set the fixed width */
        position: relative;
        background-color: var(--BackGround-color-Red-Sco);
    }
    
    .ManyGraphs-1 .Graph-4 .Graph-Top-1 .Canvas{
        margin-top: auto; /* To push the first Canvas to the top */
        margin-left: 10px;
        margin-right: 10px;
    }
    .ManyGraphs-1 .Graph-4 .Graph-Bottom-1 .Canvas{
        margin: 1px 10px; /* To push the first Canvas to the top */
    }
        /* End ManyGraphs-1 */
    /* STrat SkillsScoCover */
    .SkillsScoCover {
        position: relative;
    }
    
    .SkillsScoCover .Text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 54px;
        font-weight: 900;
        font-family: var(--Regular-Font-Family);
        color: white;
        /* width: 100%; */
        text-align: center;
    }
    
    /* End SkillsScoCover */
    /* Start BigOneGraph */
    .BigOneGraph-1{
        position: relative;
    }
    .BigOneGraph-1 .IntroBatrn{
        position: absolute;
        left: 20%;
    }
    .BigOneGraph-1 .Graph-1{
        position: absolute;
        top: 409.7px;
        left: 271px;
        width: 547px;
        height: 715.3px;        
        border-radius: 17px;
    }
    .BigOneGraph-1 .Graph-1 .Graph-Top-1 {
        width: 100%;
        height: 50%;
        margin-bottom: 2.1px;
        display: flex;
        justify-content: space-between;
        padding: 0 55px; /* Total space on both sides */
    }
    
    .BigOneGraph-1 .Graph-1 .Graph-Top-1 .BigCanvas {
        width: 65px; /* Set the fixed width */
        position: relative;
        margin-top: auto;
        top: -1px;
    }
    .BigOneGraph-1 .Graph-1 .Graph-Top-1 .BigCanvas:nth-child(1){ 
        background-color: var(--BackGround-color-Green);
    }
    .BigOneGraph-1 .Graph-1 .Graph-Top-1 .BigCanvas:nth-child(2){ 
        background-color: var(--BackGround-color-Red);
    }
    .BigOneGraph-1 .Graph-1 .Graph-Top-1 .BigCanvas:nth-child(3){ 
        background-color: var(--BackGround-color-Gray);
    }
    .BigOneGraph-1 .Graph-1 .Graph-Top-1 .BigCanvas:nth-child(4){ 
        background-color: var(--BackGround-color-Blue);
    }
    .BigOneGraph-1 .Graph-1 .Graph-Bottom-1{ 
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: space-between;
        padding: 0 55px; /* Total space on both sides */
    }
    .BigOneGraph-1 .Graph-1 .Graph-Bottom-1 .BigCanvas {
        width: 65px; /* Set the fixed width */
        position: relative;
        margin-top: 1px;
    }
    .BigOneGraph-1 .Graph-1 .Graph-Bottom-1 .BigCanvas:nth-child(1){ 
        background-color: var(--BackGround-color-Green-Sco);
    }
    .BigOneGraph-1 .Graph-1 .Graph-Bottom-1 .BigCanvas:nth-child(2){ 
        background-color: var(--BackGround-color-Red-Sco);
    }
    .BigOneGraph-1 .Graph-1 .Graph-Bottom-1 .BigCanvas:nth-child(3){ 
        background-color: var(--BackGround-color-Gray-Sco);
    }
    .BigOneGraph-1 .Graph-1 .Graph-Bottom-1 .BigCanvas:nth-child(4){ 
        background-color: var(--BackGround-color-Blue-Sco);
    }

    .BigOneGraph-1 .Graph-1 .TopBox{
        position: absolute;
        top: -110px;
        width: 545px;
        height: 100px;
        font-family: var(--Regular-Font-Family);
    }
    .BigOneGraph-1 .Graph-1 .TopBox div:nth-child(1){
        width: 100%;
    }
    .BigOneGraph-1 .Graph-1 .TopBox div:nth-child(1) span{
        display: inline-block;
        margin-left: 169px;
        width: 35%;
        height: 90%;
        border-radius: 16px;
        font-size: 23px;
        font-weight: 900;
        font-family: var(--font-family);
        color: white;
        text-align: center;
        background-color: #585a5b;
    }
    .BigOneGraph-1 .Graph-1 .TopBox div:nth-child(2){
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: row-reverse;
    }
    .BigOneGraph-1 .Graph-1 .TopBox div:nth-child(3){
        width: 100%;
        height: 50%;
    }
    .BigOneGraph-1 .Graph-1 .TopBox div:nth-child(2) span,
    .BigOneGraph-1 .Graph-1 .TopBox div:nth-child(3) span
    {
        display: inline-block;
        width: calc(100% / 4);
        height: 100%;
        border-radius: 16px;
        font-size: 26px;
        font-weight: 500;
        font-family: var(--font-family);
        color: var(--Font-Color);
        text-align: center;
    }
    .BigOneGraph-1 .Graph-1 .BottomBox{
        position: absolute;
        width: 545px;
        height: 100px;
        font-family: var(--Regular-Font-Family);
    }
    .BigOneGraph-1 .Graph-1 .BottomBox div:nth-child(3){
        width: 100%;
    }
    .BigOneGraph-1 .Graph-1 .BottomBox div:nth-child(3) span{
        display: inline-block;
        margin-left: 169px;
        width: 35%;
        height: 90%;
        border-radius: 16px;
        font-size: 23px;
        font-weight: 900;
        font-family: var(--font-family);
        color: white;
        text-align: center;
        background-color: #585a5b;
        margin-top: 12px;
    }
    .BigOneGraph-1 .Graph-1 .BottomBox div:nth-child(1){
        width: 100%;
        height: 50%;
    }
    .BigOneGraph-1 .Graph-1 .BottomBox div:nth-child(2){
        width: 100%;
        height: 50%;
    }
    .BigOneGraph-1 .Graph-1 .BottomBox div:nth-child(1) span,
    .BigOneGraph-1 .Graph-1 .BottomBox div:nth-child(2) span
    {
        display: inline-block;
        width: calc(100% / 4);
        height: 100%;
        border-radius: 16px;
        font-size: 26px;
        font-weight: 500;
        font-family: var(--font-family);
        color: var(--Font-Color);
        text-align: center;
    }


    /* End BigOneGraph */

    /* Strat GoodSkillEmpty */
    .GoodSkillEmpty{
        position: relative;
    }
    .GoodSkillEmpty .IntroBatrn{
        position: absolute;
        left: 18%;
    }
    .GoodSkillEmpty .H1Text{
        position: absolute;
        top: 18%;
        width: 80%;
        right: 80px;
        color: var(--BackGround-color-Blue);
        font-family: var(--Regular-Font-Family);
        text-align: right;
        margin-top: 45px;
    }
    .GoodSkillEmpty .BigText{
        position: absolute;
        top: 30%;
        right: 50px;
        width: 90%;
        color: var(--Font-Color);
        font-family: var(--Regular-Font-Family);
        text-align: right;
        font-size: 25px;
        line-height: 43px;
        direction: rtl;
    }
    /* End GoodSkillEmpty */
    /* Start ManyPhraseInSkills */
    .ManyPhraseInSkills-1{
        position: relative;
    }
    .ManyPhraseInSkills-1 .IntroBatrn{
        position: absolute;
        left: 17%;
    }
    .ManyPhraseInSkills-1 .SmallIntroBatrn{
        position: absolute;
        top: 18%;
        left: 42%;
        width: auto;
        height: auto;
        padding: 12px;
        color: white;
        border-radius: 18px;
        font-size: 18px;
        font-family: var(--Regular-Font-Family);
        background-color: var(--BackGround-color-Blue);
    }
    .ManyPhraseInSkills-1 .SmallIntroBatrnBottom{
        position: absolute;
        bottom: 4%;
        left: 43%;
        width: auto;
        height: auto;
        padding: 12px;
        color: white;
        border-radius: 18px;
        font-size: 18px;
        font-weight: 900;
        font-family: var(--Regular-Font-Family);
        background-color: var(--BackGround-color-Blue-Sco);
    }
    .ManyPhraseInSkills-1 .ManyPhraseBox{
        position: absolute;
        top: 22%;
        left: 27.1%;
        width: 510px;
        height: 991px;
        font-family: var(--Regular-Font-Family);
    }
    .ManyPhraseInSkills-1 .con1{
        height: 50%;
    }
    .ManyPhraseInSkills-1 .con2{
        height: 50%;
    }
    .ManyPhraseInSkills-1 .ManyPhraseBox .PhraseText{ 
        position: relative;
        top: 2px;
        width: 100%;
        height: calc((100% / 9) - 1.7px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ManyPhraseInSkills-1 .ManyPhraseBox .con1 .PhraseText{ 
        color: var(--BackGround-color-Blue-Bold);
    }
    .ManyPhraseInSkills-1 .ManyPhraseBox .con2 .PhraseText{ 
        color: var(--BackGround-color-Blue-Sco);
    }
    .ManyPhraseInSkills-1 .ManyPhraseBox .con1 .PhraseText:nth-child(1){ 
        width: 100%;
        height: 67px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ManyPhraseInSkills-1 .ManyPhraseBox .con2 .PhraseText:nth-child(9){ 
        position: relative;
        top: 2px;
        width: 100%;
        height: 66px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ManyPhraseInSkills-1 .ManyPhraseBox .PhraseText span:nth-child(1){
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 900;
    }
    /* End ManyPhraseInSkills */
    /* Start GoodSkillEmpty */
    .Empaty-3Report1{
        position: relative;
    }
    .Empaty-3Report1 .IntroBatrn{
        position: absolute;
        left: 21%;
    }
    .Empaty-3Report1 .H1Text{
        position: absolute;
        top: 18%;
        right: 80px;
        width: 80%;
        color: var(--BackGround-color-Gray);
        font-family: var(--Regular-Font-Family);
        text-align: right;
        margin-top: 45px;
    }
    .Empaty-3Report1 .BigText{
        position: absolute;
        top: 30%;
        right: 50px;
        color: var(--BackGround-color-Gray-Sco);
        font-family: var(--Regular-Font-Family);
        text-align: right;
        width: 90%;
        font-size: 25px;
        line-height: 43px;
        direction: rtl;
    }
    /* End GoodSkillEmpty */

    /* END REPORT 1 */
