
    /* START REPORT 3 */

    /* Start ManyBox-2Report-3 */
    .ManyBox-2Report-3{
        position: relative;
    }
    .ManyBox-2Report-3 .IntroBatrn{
        position: absolute;
        left: 15%
    } 
    .ManyBox-2Report-3 .BoxsContaner{
        position: absolute;
        top: 280px;
        left: 135px;
        width: 835px;
        height: 941px;
    }
    .ManyBox-2Report-3 .BoxsContaner .SmallBox{ 
        width: 100%;
        height: 81px;
        display: flex;
        position: relative;
        top: 13px;
        font-family: var(--Regular-Font-Family); 
    }
    .ManyBox-2Report-3 .BoxsContaner .SmallBox div:nth-child(1){ 
        width: 50%;
        position: absolute;
        right: 0;
    }
    .ManyBox-2Report-3 .BoxsContaner .SmallBox div:nth-child(1) span{ 
        display: inline-block;
        width: auto;
        height: auto;
        padding: 9px;
        font-size: 25px;
        font-weight: 600;
        font-family: var(--font-family);
        color: var(--BackGround-color-Gray);
        border-radius: 12px;
        padding-top: 0;
        border: 3.2px  solid var(--BackGround-color-Gray);     
        position: absolute;
        left: 36px;
    }
    .ManyBox-2Report-3 .BoxsContaner .SmallBox div:nth-child(2){ 
        width: 50%;
        position: absolute;
        left: 0;
    }
    .ManyBox-2Report-3 .BoxsContaner .SmallBox div:nth-child(2) span{ 
        display: inline-block;
        width: auto;
        height: auto;
        padding: 9px;
        font-size: 25px;
        font-weight: 600;
        font-family: var(--font-family);
        color: var(--BackGround-color-Gray-Sco);
        border-radius: 12px;
        padding-top: 0;
        border: 3.2px  solid var(--BackGround-color-Gray-Sco);     
        position: absolute;
        right: 36px;
    }

    .ManyBox-2Report-3 .BoxsContaner .BigBox{
        position: relative;
        width: 100%;
        height: 92%;
        margin-top: 6px;
        font-family: var(--Regular-Font-Family);
    }
    .ManyBox-2Report-3 .BoxsContaner .BigBox .DetilsBoxs{
        width: 100%;
        height: 85.5px;
        border-radius: 28px;
        margin-bottom: 12px;
        display: flex;
    }
    .ManyBox-2Report-3 .BoxsContaner .BigBox .DetilsBoxs span{
        font-size: 16px;
        font-weight: 900;
        font-family: var(--font-family);
        text-align: center;
        color: white;
        padding: 4px;
    }
    .ManyBox-2Report-3 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(1){
        position: relative;
        left: 13px;
        width: 100px;
        height: 87%;
        border-radius: 20px;
        border-bottom-left-radius: 28px;
        border-top-left-radius: 28px;
        }
    .ManyBox-2Report-3 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(1) span{
        position: relative;
        top: 25%;
    }
    .ManyBox-2Report-3 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(2){
        position: relative;
        left: 47px;
        width: 275px;    
        height: 87%;
        border-top-left-radius: 11px;
        border-bottom-left-radius: 11px;
        background-color: var(--BackGround-color-Black-Sco);
        padding: 0px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ManyBox-2Report-3 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(3){
        position: relative;
        left: 47px;
        width: 275px;    
        height: 87%;
        border-top-right-radius: 11px;
        border-bottom-right-radius: 11px;
        background-color: var(--BackGround-color-Black);
        padding: 0px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ManyBox-2Report-3 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(4){
        position: relative;
        left: 87px;
        width: 100px;
        height: 87%;
        border-radius: 20px;
        border-bottom-right-radius: 28px;
        border-top-right-radius: 28px;
    }
    .ManyBox-2Report-3 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(4) span{
        position: relative;
        top: 25%;
    }
        /* End ManyBox-2Report-3 */

        /* Start Empaty-8Report3 */
        .Empaty-8Report3{
            position: relative;
        }
        .Empaty-8Report3 .IntroBatrn{
            position: absolute;
            left: 15%;
        } 
        .Empaty-8Report3 .H1Text{
            position: absolute;
            top: 19%;
            right: 50px;
            color: var(--BackGround-color-Red);
            font-family: var(--Bold-Font-Family);
            text-align: right;
            width: 90%;
        }
        .Empaty-8Report3 .BigText{
            position: absolute;
            top: 31%;
            right: 50px;
            color: var(--BackGround-color-Red);
            font-family: var(--Regular-Font-Family);
            text-align: right;
            width: 90%;
            font-size: 25px;
            line-height: 41px;
            direction: rtl;        
        }
            /* End Empaty-8Report3 */

            /* Start ManyBox-3Report-3 */
    .ManyBox-3Report-3{
        position: relative;
    }
    .ManyBox-3Report-3 .IntroBatrn{
        position: absolute;
        left: 21%;
    } 
    .ManyBox-3Report-3 .BoxsContaner{
        position: absolute;
        top: 288.9px;
        left: 135px;
        width: 835px;
        height: 941px;
    }
    .ManyBox-3Report-3 .BoxsContaner .SmallBox{ 
        width: 100%;
        height: 78.7px;
        display: flex;
        position: relative;
        top: 13px;
        font-family: var(--Regular-Font-Family); 
    }
    .ManyBox-3Report-3 .BoxsContaner .SmallBox div:nth-child(1){ 
        width: 50%;
        position: absolute;
        right: 0;
    }
    .ManyBox-3Report-3 .BoxsContaner .SmallBox div:nth-child(1) span{ 
        display: inline-block;
        width: auto;
        height: auto;
        padding: 9px;
        font-size: 25px;
        font-weight: 600;
        font-family: var(--font-family);
        color: var(--BackGround-color-Red);
        border-radius: 12px;
        padding-top: 0;
        border: 3.2px  solid var(--BackGround-color-Red); 
        position: absolute;
        left: 36px;
    }
    .ManyBox-3Report-3 .BoxsContaner .SmallBox div:nth-child(2){ 
        width: 50%;
        position: absolute;
        left: 0;
    }
    .ManyBox-3Report-3 .BoxsContaner .SmallBox div:nth-child(2) span{ 
        display: inline-block;
        width: auto;
        height: auto;
        padding: 9px;
        font-size: 25px;
        font-weight: 600;
        font-family: var(--font-family);
        color: var(--BackGround-color-Red-Sco);
        border-radius: 12px;
        padding-top: 0;
        border: 3.2px  solid var(--BackGround-color-Red-Sco); 
        position: absolute;
        right: 36px;
    }
    .ManyBox-3Report-3 .BoxsContaner .BigBox{
        position: relative;
        width: 100%;
        height: 92%;
        font-family: var(--Regular-Font-Family);
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    .ManyBox-3Report-3 .BoxsContaner .BigBox .DetilsBoxs{
        width: 100%;
        height: 86px;
        border-radius: 28px;
        display: flex;
        margin-top: 0.1px;
    }
    .ManyBox-3Report-3 .BoxsContaner .BigBox .DetilsBoxs span{
        font-size: 18px;
        font-weight: 900;
        font-family: var(--font-family);
        text-align: center;
        color: white;
        padding: 4px;
    }
    .ManyBox-3Report-3 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(1){
        position: relative;
        left: 14px;
        width: 100px;
        height: 87%;
        margin: auto 0;
        border-radius: 20px;
        border-bottom-left-radius: 28px;
        border-top-left-radius: 28px;
    }
    .ManyBox-3Report-3 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(1) span{
        position: relative;
        top: 25%;
    }
    .ManyBox-3Report-3 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(2){
        position: relative;
        left: 48px;
        width: 269px;
        height: 87%;
        border-top-left-radius: 11px;
        border-bottom-left-radius: 11px;
        background-color: var(--BackGround-color-Red-Sco);
        padding: 0px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto 0;
    }
    .ManyBox-3Report-3 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(3){
        position: relative;
        left: 55px;
        width: 269px;
        height: 87%;
        border-top-right-radius: 11px;
        border-bottom-right-radius: 11px;
        background-color: var(--BackGround-color-Red);
        padding: 0px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto 0;
    }
    .ManyBox-3Report-3 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(4){
        position: relative;
        left: 99px;
        width: 100px;
        height: 90%;
        border-radius: 20px;
        border-bottom-right-radius: 28px;
        border-top-right-radius: 28px;
        margin: auto 0;
    }
    .ManyBox-3Report-3 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(4) span{
        position: relative;
        top: 25%;
    }
            /* End ManyBox-3Report-3 */

            /* Start Empaty-9Report3 */
        .Empaty-9Report3{
            position: relative;
        }
        .Empaty-9Report3 .IntroBatrn{
            position: absolute;
            left: 13%;
        } 
        .Empaty-9Report3 .H1Text{
            position: absolute;
            top: 21%;
            right: 50px;
            color: var(--BackGround-color-Green);
            font-family: var(--Bold-Font-Family);
            text-align: right;
            width: 90%;
        }
        .Empaty-9Report3 .BigText{
            position: absolute;
            top: 32%;
            right: 50px;
            color: var(--BackGround-color-Green);
            font-family: var(--Regular-Font-Family);
            text-align: right;
            width: 90%;
            font-size: 25px;
            line-height: 40px;
            direction: rtl;
        }
            /* End Empaty-9Report3 */

            /* Start ManyBox-4Report-3 */
    .ManyBox-4Report-3{
        position: relative;
    }
    .ManyBox-4Report-3 .IntroBatrn{
        position: absolute;
        left: 20%;
    } 
    .ManyBox-4Report-3 .BoxsContaner{
        position: absolute;
        top: 280px;
        left: 135px;
        width: 835px;
        height: 941px;
    }
    .ManyBox-4Report-3 .BoxsContaner .SmallBox{ 
        width: 100%;
        height: 90px;
        display: flex;
        position: relative;
        top: 13px;
        font-family: var(--Regular-Font-Family); 
    }
    .ManyBox-4Report-3 .BoxsContaner .SmallBox div:nth-child(1){ 
        width: 50%;
        position: absolute;
        right: 0;
    }
    .ManyBox-4Report-3 .BoxsContaner .SmallBox div:nth-child(1) span{ 
        display: inline-block;
        width: auto;
        height: auto;
        padding: 9px;
        font-size: 25px;
        font-weight: 600;
        font-family: var(--font-family);
        color: var(--BackGround-color-Green);
        border-radius: 12px;
        padding-top: 0;
        border: 3.2px  solid var(--BackGround-color-Green); 
        position: absolute;
        left: 36px;
    }
    .ManyBox-4Report-3 .BoxsContaner .SmallBox div:nth-child(2){ 
        width: 50%;
        position: absolute;
        left: 0;
    }
    .ManyBox-4Report-3 .BoxsContaner .SmallBox div:nth-child(2) span{ 
        display: inline-block;
        width: auto;
        height: auto;
        padding: 9px;
        font-size: 25px;
        font-weight: 600;
        font-family: var(--font-family);
        color: var(--BackGround-color-Green-Sco);
        border-radius: 12px;
        padding-top: 0;
        border: 3.2px  solid var(--BackGround-color-Green-Sco); 
        position: absolute;
        right: 36px;
    }
    .ManyBox-4Report-3 .BoxsContaner .BigBox{
        position: relative;
        width: 100%;
        height: 92%;
        margin-top: 5.5px;
        font-family: var(--Regular-Font-Family);
    }
    .ManyBox-4Report-3 .BoxsContaner .BigBox .DetilsBoxs{
        width: 100%;
        height: 85.5px;
        border-radius: 28px;
        margin-bottom: 12.2px;
        display: flex;
    }
    .ManyBox-4Report-3 .BoxsContaner .BigBox .DetilsBoxs span{
        font-size: 18px;
        font-weight: 900;
        font-family: var(--font-family);
        text-align: center;
        color: white;
        padding: 4px;
    }
    .ManyBox-4Report-3 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(1){
        position: relative;
        left: 15px;
        width: 100px;
        height: 90%;
        border-radius: 20px;
        border-bottom-left-radius: 28px;
        border-top-left-radius: 28px;
    }
    .ManyBox-4Report-3 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(1) span{
        position: relative;
        top: 25%;
    }
    .ManyBox-4Report-3 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(2){
        position: relative;
        top: 1px;
        left: 53px;
        width: 268px;
        height: 88%;
        background-color: var(--BackGround-color-Green-Sco);
        border-top-left-radius: 11px;
        border-bottom-left-radius: 11px;
        padding: 0px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ManyBox-4Report-3 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(3){
        position: relative;
        top: 1px;
        left: 60px;
        width: 268px;
        height: 88%;
        background-color: var(--BackGround-color-Green);
        border-top-right-radius: 11px;
        border-bottom-right-radius: 11px;
        padding: 0px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ManyBox-4Report-3 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(4){
        position: relative;
        left: 104px;
        width: 100px;
        height: 87%;
        border-radius: 20px;
        border-bottom-right-radius: 28px;
        border-top-right-radius: 28px;
    }
    .ManyBox-4Report-3 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(4) span{
        position: relative;
        top: 25%;
    }
        /* End ManyBox-4Report-3 */
    /* Start ScoCover-3 */
    .ScoCover-3{
        position: relative;
    }
    .ScoCover-3 .Text{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 65px;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
        text-align: center; /* Center the text horizontally */
        white-space: nowrap; /* Prevent line breaks */
        line-height: 1.2; /* Adjust line height as needed */
    }
    /* End ScoCover-3 */  
        /* Start BigOneGraph-3 */
        .BigOneGraph-3{
            position: relative;
        }
        .BigOneGraph-3 .IntroBatrn{
            position: absolute;
            left: 25%;
        } 
        .BigOneGraph-3 .BottomTitleGraphCon{
            position: absolute;
            bottom: 8%;
            width: 100%;
        }
        .BigOneGraph-3 .BottomTitleGraphCon .BottomTitleGraph{
            font-size: 26px;
            font-family: var(--Regular-Font-Family);
            color: var(--BackGround-color-Blue);
            font-weight: 900;
            margin: auto;
            width: 260px;
            display: flex;
            justify-content: center;
        }   
        .BigOneGraph-3 .Graph-1{
            position: absolute;
            top: 407px;
            left: 274px;
            width: 542px;
            height: 714px;        
            border-radius: 12px;
        }
        .BigOneGraph-3 .Graph-1 .Graph-Top-1 {
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: space-between;
            padding: 0 55px; /* Total space on both sides */
        }
        
        .BigOneGraph-3 .Graph-1 .Graph-Top-1 .BigCanvas {
            width: 65px; /* Set the fixed width */
            position: relative;
            margin-top: auto;
            top: -1px;
        }
        .BigOneGraph-3 .Graph-1 .Graph-Top-1 .BigCanvas:nth-child(1){ 
            background-color: var(--BackGround-color-Green);
        }
        .BigOneGraph-3 .Graph-1 .Graph-Top-1 .BigCanvas:nth-child(2){ 
            background-color: var(--BackGround-color-Red);
        }
        .BigOneGraph-3 .Graph-1 .Graph-Top-1 .BigCanvas:nth-child(3){ 
            background-color: var(--BackGround-color-Gray);
        }
        .BigOneGraph-3 .Graph-1 .Graph-Top-1 .BigCanvas:nth-child(4){ 
            background-color: var(--BackGround-color-Blue);
        }
        .BigOneGraph-3 .Graph-1 .Graph-Bottom-1{ 
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: space-between;
            padding: 0 55px; /* Total space on both sides */
        }
        .BigOneGraph-3 .Graph-1 .Graph-Bottom-1 .BigCanvas {
            width: 65px; /* Set the fixed width */
            position: relative;
            /* background-color: var(--BackGround-color-Green-Sco); */
            margin-top: 1px;
        }
        .BigOneGraph-3 .Graph-1 .Graph-Bottom-1 .BigCanvas:nth-child(1){ 
            background-color: var(--BackGround-color-Green-Sco);
        }
        .BigOneGraph-3 .Graph-1 .Graph-Bottom-1 .BigCanvas:nth-child(2){ 
            background-color: var(--BackGround-color-Red-Sco);
        }
        .BigOneGraph-3 .Graph-1 .Graph-Bottom-1 .BigCanvas:nth-child(3){ 
            background-color: var(--BackGround-color-Gray-Sco);
        }
        .BigOneGraph-3 .Graph-1 .Graph-Bottom-1 .BigCanvas:nth-child(4){ 
            background-color: var(--BackGround-color-Blue-Sco);
        }
    
        .BigOneGraph-3 .Graph-1 .TopBox{
            position: absolute;
            top: -110px;
            width: 537px;
            height: 100px;
            font-family: var(--Regular-Font-Family);
        }
        .BigOneGraph-3 .Graph-1 .TopBox div:nth-child(1){
            width: 100%;
        }
        .BigOneGraph-3 .Graph-1 .TopBox div:nth-child(1) span{
            display: inline-block;
            margin-left: 169px;
            width: 35%;
            height: 90%;
            border-radius: 16px;
            font-size: 23px;
            font-weight: 900;
            font-family: var(--font-family);
            color: white;
            text-align: center;
            background-color: #585a5b;
        }
        .BigOneGraph-3 .Graph-1 .TopBox div:nth-child(2){
            width: 100%;
            height: 50%;
            display: flex;
            flex-direction: row-reverse;
        }
        .BigOneGraph-3 .Graph-1 .TopBox div:nth-child(3){
            width: 100%;
            height: 25%;
            margin-left: 0px;
        }
        .BigOneGraph-3 .Graph-1 .TopBox div:nth-child(2) span,
        .BigOneGraph-3 .Graph-1 .TopBox div:nth-child(3) span
        {
            display: inline-block;
            /* margin-left: 30px; */
            width: calc(100% / 4);
            height: 100%;
            border-radius: 16px;
            font-size: 26px;
            font-weight: 500;
            font-family: var(--font-family);
            color: var(--Font-Color);
            text-align: center;
        }
        .BigOneGraph-3 .Graph-1 .BottomBox{
            position: absolute;
            width: 537px;
            height: 100px;
            font-family: var(--Regular-Font-Family);
        }
        .BigOneGraph-3 .Graph-1 .BottomBox div:nth-child(3){
            width: 100%;
            height: 50%;
        }
        .BigOneGraph-3 .Graph-1 .BottomBox div:nth-child(3) span{
            display: inline-block;
            margin-left: 169px;
            width: 35%;
            height: 90%;
            border-radius: 16px;
            font-size: 23px;
            font-weight: 900;
            font-family: var(--font-family);
            color: white;
            text-align: center;
            background-color: #585a5b;
            margin-top: 12px;
        }
        .BigOneGraph-3 .Graph-1 .BottomBox div:nth-child(1){
            width: 100%;
            height: 50%;
        }
        .BigOneGraph-3 .Graph-1 .BottomBox div:nth-child(2){
            width: 100%;
            height: 50%;
            display: flex;
            flex-direction: row-reverse;
        }
        .BigOneGraph-3 .Graph-1 .BottomBox div:nth-child(1) span,
        .BigOneGraph-3 .Graph-1 .BottomBox div:nth-child(2) span
        {
            display: inline-block;
            width: calc(100% / 4);
            height: 100%;
            border-radius: 16px;
            font-size: 26px;
            font-weight: 500;
            font-family: var(--font-family);
            color: var(--Font-Color);
            text-align: center;
        }
        /* End BigOneGraph-3 */
        /* Start Btn-1 */
        .Btn-1{
            position: relative;
        }
        .Btn-1 .IntroBatrn{
            position: absolute;
            left: 22%;
        } 
        .Btn-1  .BtnsContaner{
            position: absolute;
            top: 325px;
            left: 35px;
            width: 93%;
            font-family: var(--Regular-Font-Family);
        } 
        .Btn-1  .BtnsContaner .BtnCon{
            display: inline-block;
            width: 50%;
        } 
        .Btn-1  .BtnsContaner .BtnCon .ButtonsText{
            display: flex;
            position: relative;
            top: 90px;
            width: 100%;
            z-index: 1000;
        } 
        .Btn-1  .BtnsContaner .BtnCon .ButtonsText div:nth-child(1){
            position: absolute;
            top: -8px;
            right: 30px;
            color: white;
            font-size: 22px;
            font-family: var(--Bold-Font-Family);
        } 
        .Btn-1  .BtnsContaner .BtnCon .ButtonsText .left{
            position: absolute;
            right: 213px;
            top: -42px;
            width: 178px;
            height: 108px;
            color: white;
            font-size: 13px;
            border-radius: 60px;
            padding: 0 8px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        } 
        .Btn-1  .BtnsContaner .BtnCon .ButtonsText .Right{
            position: absolute;
            left: 232px;
            top: -46px;
            width: 173px;
            height: 108px;
            color: white;
            font-size: 13px;
            border-radius: 60px;
            padding: 0 12px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        } 
        .Btn-1  .BtnsContaner .BtnCon .ButtonsText div:nth-child(3){
            position: absolute;
            top: -8px;
            left: 26px;
            color: white;
            font-size: 22px;
            font-family: var(--Bold-Font-Family);
        } 
        .Btn-1  .BtnsContaner  .ButtonImage{
            width: 483px;
            height: 175px;
            margin: 19px;
        } 
        /* End Btn-1 */
        /* Start Btn-2 */
        .Btn-2{
            position: relative;
        }
        .Btn-2 .IntroBatrn{
            position: absolute;
            left: 22%;
        } 
        .Btn-2  .BtnsContaner{
            position: absolute;
            top: 325px;
            left: 35px;
            width: 93%;
            font-family: var(--Regular-Font-Family);
        } 
        .Btn-2  .BtnsContaner .BtnCon{
            display: inline-block;
            width: 50%;
        } 
        .Btn-2  .BtnsContaner .BtnCon .ButtonsText{
            display: flex;
            position: relative;
            top: 90px;
            width: 100%;
            z-index: 1000;
        } 
        .Btn-2  .BtnsContaner .BtnCon .ButtonsText div:nth-child(1){
            position: absolute;
            top: -8px;
            right: 30px;
            color: white;
            font-size: 22px;
            font-family: var(--Bold-Font-Family);
        } 
        .Btn-2  .BtnsContaner .BtnCon .ButtonsText .left{
            position: absolute;
            right: 213px;
            top: -42px;
            width: 178px;
            height: 103px;
            color: white;
            font-size: 13px;
            border-radius: 60px;
            padding: 0 12px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        } 
        .Btn-2  .BtnsContaner .BtnCon .ButtonsText .Right{
            position: absolute;
            left: 232px;
            top: -46px;
            width: 173px;
            height: 103px;
            color: white;
            font-size: 13px;
            border-radius: 60px;
            padding: 0 12px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        } 
        .Btn-2  .BtnsContaner .BtnCon .ButtonsText div:nth-child(3){
            position: absolute;
            top: -8px;
            left: 26px;
            color: white;
            font-size: 22px;
            font-family: var(--Bold-Font-Family);
        } 
        .Btn-2  .BtnsContaner  .ButtonImage{
            width: 483px;
            height: 175px;
            margin: 19px;
        }

        /* End Btn-2 */
        /* Start Btn-3 */
        .Btn-3{
            position: relative;
        }
        .Btn-3 .IntroBatrn{
            position: absolute;
            left: 20%;
        } 
        .Btn-3  .BtnsContaner{
            position: absolute;
            top: 325px;
            left: 35px;
            width: 93%;
            font-family: var(--Regular-Font-Family);
        } 
        .Btn-3  .BtnsContaner .BtnCon{
            display: inline-block;
            width: 50%;
        } 
        .Btn-3  .BtnsContaner .BtnCon .ButtonsText{
            display: flex;
            position: relative;
            top: 90px;
            width: 100%;
            z-index: 1000;
        } 
        .Btn-3  .BtnsContaner .BtnCon .ButtonsText div:nth-child(1){
            position: absolute;
            top: -8px;
            right: 30px;
            color: white;
            font-size: 22px;
            font-family: var(--Bold-Font-Family);
        } 
        .Btn-3  .BtnsContaner .BtnCon .ButtonsText .left{
            position: absolute;
            right: 213px;
            top: -42px;
            width: 178px;
            height: 108px;
            color: white;
            font-size: 13px;
            border-radius: 60px;
            padding: 0 8px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        } 
        .Btn-3  .BtnsContaner .BtnCon .ButtonsText .Right{
            position: absolute;
            left: 232px;
            top: -46px;
            width: 173px;
            height: 108px;
            color: white;
            font-size: 13px;
            border-radius: 60px;
            padding: 0 12px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        } 
        .Btn-3  .BtnsContaner .BtnCon .ButtonsText div:nth-child(3){
            position: absolute;
            top: -8px;
            left: 26px;
            color: white;
            font-size: 22px;
            font-family: var(--Bold-Font-Family);
        } 
        .Btn-3  .BtnsContaner  .ButtonImage{
            width: 483px;
            height: 175px;
            margin: 19px;
        }

        /* End Btn-3 */
        /* Start Btn-4 */
        .Btn-4{
            position: relative;
        }
        .Btn-4 .IntroBatrn{
            position: absolute;
            left: 20%;
        } 
        .Btn-4  .BtnsContaner{
            position: absolute;
            top: 325px;
            left: 35px;
            width: 93%;
            font-family: var(--Regular-Font-Family);
        } 
        .Btn-4  .BtnsContaner .BtnCon{
            display: inline-block;
            width: 50%;
        } 
        .Btn-4  .BtnsContaner .BtnCon .ButtonsText{
            display: flex;
            position: relative;
            top: 90px;
            width: 100%;
            z-index: 1000;
        } 
        .Btn-4  .BtnsContaner .BtnCon .ButtonsText div:nth-child(1){
            position: absolute;
            top: -8px;
            right: 30px;
            color: white;
            font-size: 22px;
            font-family: var(--Bold-Font-Family);
        } 
        .Btn-4  .BtnsContaner .BtnCon .ButtonsText .left{
            position: absolute;
            right: 213px;
            top: -42px;
            width: 178px;
            height: 108px;
            color: white;
            font-size: 13px;
            border-radius: 60px;
            padding: 0 8px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        } 
        .Btn-4  .BtnsContaner .BtnCon .ButtonsText .Right{
            position: absolute;
            left: 232px;
            top: -46px;
            width: 173px;
            height: 108px;
            color: white;
            font-size: 13px;
            border-radius: 60px;
            padding: 0 12px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        } 
        .Btn-4  .BtnsContaner .BtnCon .ButtonsText div:nth-child(3){
            position: absolute;
            top: -8px;
            left: 26px;
            color: white;
            font-size: 22px;
            font-family: var(--Bold-Font-Family);
        } 
        .Btn-4  .BtnsContaner  .ButtonImage{
            width: 483px;
            height: 175px;
            margin: 19px;
        }
        /* End Btn-4 */
    
/* END REPORT 3 */
