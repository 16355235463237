    /* START REPORT 2 */
    
    /* Start ManyPhraseInSkills-2 */
    .ManyPhraseInSkills-2{
        position: relative;
    }
    .ManyPhraseInSkills-2 .IntroBatrn{
        position: absolute;
        left: 21%;
        top: 175px;
    }
    .ManyPhraseInSkills-2 .SmallIntroBatrn{
        position: absolute;
        top: 18%;
        left: 42%;
        width: auto;
        height: auto;
        padding: 12px;
        color: white;
        border-radius: 18px;
        font-size: 18px;
        font-weight: 900;
        font-family: var(--Regular-Font-Family);
        background-color: var(--BackGround-color-Gray);
    }
    .ManyPhraseInSkills-2 .SmallIntroBatrnBottom{
        position: absolute;
        bottom: 4.5%;
        left: 42%;
        width: auto;
        height: auto;
        padding: 12px;
        color: white;
        border-radius: 18px;
        font-size: 18px;
        font-weight: 991px;
        font-family: var(--Regular-Font-Family);
        background-color: var(--BackGround-color-Gray-Sco);
    }
    .ManyPhraseInSkills-2 .ManyPhraseBox{
        position: absolute;
        top: 21.8%;
        left: 26.5%;
        width: 505.1px;
        height: 992px;
        font-family: var(--Regular-Font-Family);
    }
    .ManyPhraseInSkills-2 .ManyPhraseBox .con3,
    .ManyPhraseInSkills-2 .ManyPhraseBox .con4{
        height: 50%;
    }
    .ManyPhraseInSkills-2 .ManyPhraseBox .PhraseText{ 
        position: relative;
        top: 2px;
        width: 100%;
        height: calc((100% / 9) - 1.7px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ManyPhraseInSkills-2 .ManyPhraseBox .con3 .PhraseText:nth-child(1){ 
        position: relative;
        top: 2px;
        width: 100%;
        height: 67px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ManyPhraseInSkills-2 .ManyPhraseBox .con4 .PhraseText:nth-child(9){ 
        position: relative;
        top: 2px;
        width: 100%;
        height: 67px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ManyPhraseInSkills-2 .con3 .PhraseText{ 
        color: var(--BackGround-color-Gray);
    } 
    .ManyPhraseInSkills-2  .con4 .PhraseText{ 
        color: var(--BackGround-color-Gray-Sco);
    }
    .ManyPhraseInSkills-2 .ManyPhraseBox .PhraseText span:nth-child(1){
        width: 94%;
        height: 100%;
        font-size: 20px;
        font-weight: 900;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -6px;
    }
    /* End ManyPhraseInSkills-2 */
        /* Start Empaty-4Report2 */
        .Empaty-4Report2{
            position: relative;
        }
        .Empaty-4Report2 .IntroBatrn{
            position: absolute;
            left: 23%;
        }

        .Empaty-4Report2 .H1Text{
            position: absolute;
            top: 18%;
            right: 80px;
            width: 80%;
            color: var(--BackGround-color-Red);
            font-family: var(--Regular-Font-Family);
            text-align: right;
            margin-top: 45px;
        }
        .Empaty-4Report2 .BigText{
            position: absolute;
            top: 30%;
            right: 50px;
            color: var(--BackGround-color-Red-Sco);
            font-family: var(--font-family);
            text-align: right;
            width: 90%;
            font-size: 25px;
            font-family: var(--Regular-Font-Family);
            line-height: 45px;
            direction: rtl;
        }
        /* End Empaty-4Report2 */
    
    
    /* Start ManyPhraseInSkills-3 */
    .ManyPhraseInSkills-3{
        position: relative;
    }
    .ManyPhraseInSkills-3 .IntroBatrn{
        position: absolute;
        left: 24%;
        top: 168px;
    }
    .ManyPhraseInSkills-3 .SmallIntroBatrn{
        position: absolute;
        top: 18%;
        left: 43%;
        width: auto;
        height: auto;
        padding: 12px;
        color: white;
        border-radius: 18px;
        font-size: 18px;
        font-weight: 900;
        font-family: var(--Regular-Font-Family);
        background-color: var(--BackGround-color-Red);
    }
    .ManyPhraseInSkills-3 .SmallIntroBatrnBottom{
        position: absolute;
        bottom: 4.5%;
        left: 44%;    
        width: auto;
        height: auto;
        padding: 12px;
        color: white;
        border-radius: 18px;
        font-size: 18px;
        font-weight: 900;
        font-family: var(--Regular-Font-Family);
        background-color: var(--BackGround-color-Red-Sco);
    }
    .ManyPhraseInSkills-3 .ManyPhraseBox{
        position: absolute;
        top: 22.1%;
        left: 27.2%;
        width: 507px;
        height: 982px;
        font-family: var(--Regular-Font-Family);
    }
    .ManyPhraseInSkills-3 .con5{
        height: 50%;
    }
    .ManyPhraseInSkills-3 .con6{
        height: 50%;
    }
    .ManyPhraseInSkills-3 .ManyPhraseBox .PhraseText{ 
        position: relative;
        top: -1px;
        width: 100%;
        height: calc((100% / 9));
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: var(--Regular-Font-Family);
    }
    .ManyPhraseInSkills-3 .ManyPhraseBox .con5 .PhraseText{ 
        color: var(--BackGround-color-Red);
    }
    .ManyPhraseInSkills-3 .ManyPhraseBox .con6 .PhraseText{
        color: var(--BackGround-color-Red-Sco);
     }
     .ManyPhraseInSkills-3 .ManyPhraseBox .con5 .PhraseText:nth-child(1){ 
        width: 100%;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ManyPhraseInSkills-3 .ManyPhraseBox .con6 .PhraseText:nth-child(9){ 
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ManyPhraseInSkills-3 .ManyPhraseBox .PhraseText span:nth-child(1){
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 900;
    }
    /* End ManyPhraseInSkills-3 */

        /* Start Empaty-5Report2 */
        .Empaty-5Report2{
            position: relative;
        }
        .Empaty-5Report2 .IntroBatrn{
            position: absolute;
            left: 22%;
        }
        .Empaty-5Report2 .H1Text{
            position: absolute;
            top: 18%;
            right: 80px;
            width: 80%;
            color: var(--BackGround-color-Green);
            font-family: var(--Regular-Font-Family);
            text-align: right;
            margin-top: 45px;
        }
        .Empaty-5Report2 .BigText{
            position: absolute;
            top: 30%;
            right: 50px;
            color: var(--BackGround-color-Green-Sco);
            font-family: var(--Regular-Font-Family);
            text-align: right;
            width: 90%;
            font-size: 25px;
            line-height: 37px;
            direction: rtl;
        }
        /* End Empaty-5Report2 */
            /* Start ManyPhraseInSkills-4 */
    .ManyPhraseInSkills-4{
        position: relative;
    }
    .ManyPhraseInSkills-4 .IntroBatrn{
        position: absolute;
        left: 22%;
        top: 167px;    
    }
    .ManyPhraseInSkills-4 .SmallIntroBatrn{
        position: absolute;
        top: 17.5%;
        left: 44%;
        width: auto;
        height: auto;
        padding: 12px;
        color: white;
        border-radius: 18px;
        font-size: 18px;
        font-weight: 900;
        font-family: var(--Regular-Font-Family);
        background-color: var(--BackGround-color-Green);
    }
    .ManyPhraseInSkills-4 .SmallIntroBatrnBottom{
        position: absolute;
        bottom: 4.5%;
        left: 42%;
        width: auto;
        height: auto;
        padding: 12px;
        color: white;
        border-radius: 18px;
        font-size: 18px;
        font-weight: 900;
        font-family: var(--Regular-Font-Family);
        background-color: var(--BackGround-color-Green-Sco);
    }
    .ManyPhraseInSkills-4 .ManyPhraseBox{
        position: absolute;
        top: 22.1%;
        left: 27.2%;
        width: 507px;
        height: 991px;
    }
    .ManyPhraseInSkills-4 .con7{
        height: 50%;
    }
    .ManyPhraseInSkills-4 #con8{
        height: 50%;
    }
    .ManyPhraseInSkills-4 .ManyPhraseBox .PhraseText{ 
        position: relative;
        top: 2px;
        width: 100%;
        height: calc((100% / 9) - 1.7px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ManyPhraseInSkills-4 .ManyPhraseBox .con7 .PhraseText{ 
        color: var(--BackGround-color-Green);
    }
    .ManyPhraseInSkills-4 .ManyPhraseBox #con8 .PhraseText{
        color: var(--BackGround-color-Green-Sco);
     }
     .ManyPhraseInSkills-4 .ManyPhraseBox .con7 .PhraseText:nth-child(1){ 
        width: 100%;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ManyPhraseInSkills-4 .ManyPhraseBox #con8 .PhraseText:nth-child(9){ 
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ManyPhraseInSkills-4 .ManyPhraseBox .PhraseText span:nth-child(1){
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 900;
        font-family: var(--Regular-Font-Family);
    }
    /* End ManyPhraseInSkills-2 */
        /* Start ScoCover-2 */
        .ScoCover-2{
            position: relative;
        }
        .ScoCover-2 .Text{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 65px;
            font-weight: 900;
            font-family: var(--Regular-Font-Family);
            color: white;
            text-align: center; /* Center the text horizontally */
            white-space: nowrap; /* Prevent line breaks */
            line-height: 1.2; /* Adjust line height as needed */
        }
        /* End ScoCover-2 */
        /* Start BigOneGraph-2 */
        .BigOneGraph-2{
            position: relative;
        }
        .BigOneGraph-2 .IntroBatrn{
            position: absolute;
            left: 25%;
        }   
        .BigOneGraph-2 .BottomTitleGraphCon{
            position: absolute;
            bottom: 8%;
            width: 100%;
        }
        .BigOneGraph-2 .BottomTitleGraphCon .BottomTitleGraph{
            font-size: 26px;
            font-family: var(--Regular-Font-Family);
            color: var(--BackGround-color-Blue);
            font-weight: 900;
            margin: auto;
            width: 260px;
            display: flex;
            justify-content: center;
        }   
        .BigOneGraph-2 .Graph-1{
            position: absolute;
            top: 360px;
            left: 274px;
            width: 542px;
            height: 712px;
            border-radius: 12px;
        }
        .BigOneGraph-2 .Graph-1 .Graph-Top-1 {
            width: 100%;
            height: 50%;
            margin-bottom: 2.1px;
            display: flex;
            justify-content: space-between;
            padding: 0 55px; /* Total space on both sides */
        }
        
        .BigOneGraph-2 .Graph-1 .Graph-Top-1 .BigCanvas {
            width: 65px; /* Set the fixed width */
            position: relative;
            margin-top: auto;
            top: -1px;
        }
        .BigOneGraph-2 .Graph-1 .Graph-Top-1 .BigCanvas:nth-child(1){ 
            background-color: var(--BackGround-color-Green);
        }
        .BigOneGraph-2 .Graph-1 .Graph-Top-1 .BigCanvas:nth-child(2){ 
            background-color: var(--BackGround-color-Red);
        }
        .BigOneGraph-2 .Graph-1 .Graph-Top-1 .BigCanvas:nth-child(3){ 
            background-color: var(--BackGround-color-Gray);
        }
        .BigOneGraph-2 .Graph-1 .Graph-Top-1 .BigCanvas:nth-child(4){ 
            background-color: var(--BackGround-color-Blue);
        }
        .BigOneGraph-2 .Graph-1 .Graph-Bottom-1{ 
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: space-between;
            padding: 0 55px; /* Total space on both sides */
        }
        .BigOneGraph-2 .Graph-1 .Graph-Bottom-1 .BigCanvas {
            width: 65px; /* Set the fixed width */
            position: relative;
            margin-top: 1px;
        }
        .BigOneGraph-2 .Graph-1 .Graph-Bottom-1 .BigCanvas:nth-child(1){ 
            background-color: var(--BackGround-color-Green-Sco);
        }
        .BigOneGraph-2 .Graph-1 .Graph-Bottom-1 .BigCanvas:nth-child(2){ 
            background-color: var(--BackGround-color-Red-Sco);
        }
        .BigOneGraph-2 .Graph-1 .Graph-Bottom-1 .BigCanvas:nth-child(3){ 
            background-color: var(--BackGround-color-Gray-Sco);
        }
        .BigOneGraph-2 .Graph-1 .Graph-Bottom-1 .BigCanvas:nth-child(4){ 
            background-color: var(--BackGround-color-Blue-Sco);
        }
    
        .BigOneGraph-2 .Graph-1 .TopBox{
            position: absolute;
            top: -110px;
            width: 545px;
            height: 100px;
            font-family: var(--Regular-Font-Family);
        }
        .BigOneGraph-2 .Graph-1 .TopBox div:nth-child(1){
            width: 100%;
            height: 50%;
            display: flex;
            flex-direction: row-reverse;
        }
        .BigOneGraph-2 .Graph-1 .TopBox div:nth-child(1) span{
            width: calc(100% / 4 - 14px);
            margin-left: 10px;
            color: var(--Font-Color);
            font-size: 26px;
            font-weight: 500;
            font-family: var(--font-family);
        }
        .BigOneGraph-2 .Graph-1 .TopBox div:nth-child(2){
            width: 100%;
            height: 50%;
        }
        .BigOneGraph-2 .Graph-1 .TopBox div:nth-child(3){
            width: 100%;
            height: 25%;
            margin-left: 20px;
        }
        .BigOneGraph-2 .Graph-1 .TopBox div:nth-child(2) span,
        .BigOneGraph-2 .Graph-1 .TopBox div:nth-child(3) span
        {
            display: inline-block;
            margin-left: 29px;
            width: 100px;
            height: 100%;
            border-radius: 16px;
            font-size: 26px;
            font-weight: 500;
            font-family: var(--font-family);
            color: var(--Font-Color);
            text-align: center;
        }
        .BigOneGraph-2 .Graph-1 .BottomBox{
            position: absolute;
            width: 545px;
            height: 100px;
            font-family: var(--Regular-Font-Family);
        }
        .BigOneGraph-2 .Graph-1 .BottomBox div:nth-child(3){
            width: 100%;
        }
        .BigOneGraph-2 .Graph-1 .BottomBox div:nth-child(3) span{
            display: inline-block;
            margin-left: 169px;
            width: 35%;
            height: 90%;
            border-radius: 16px;
            font-size: 23px;
            font-weight: 900;
            font-family: var(--font-family);
            color: white;
            text-align: center;
            background-color: #585a5b;
            margin-top: 12px;
        }
        .BigOneGraph-2 .Graph-1 .BottomBox div:nth-child(1){
            width: 100%;
            height: 50%;
        }
        .BigOneGraph-2 .Graph-1 .BottomBox div:nth-child(2){
            width: 100%;
            height: 50%;
            display: flex;
            flex-direction: row-reverse;
        }
        .BigOneGraph-2 .Graph-1 .BottomBox div:nth-child(1) span,
        .BigOneGraph-2 .Graph-1 .BottomBox div:nth-child(2) span
        {
            display: inline-block;
            /* margin-left: 30px; */
            width: calc(100% / 4);
            height: 100%;
            border-radius: 16px;
            font-size: 26px;
            font-weight: 500;
            font-family: var(--font-family);
            color: var(--Font-Color);
            text-align: center;
        }
    
        /* End BigOneGraph-2 */
    
        /* Start Empaty-6Report2 */
        .Empaty-6Report2{
            position: relative;
        }
        .Empaty-6Report2 .IntroBatrn{
            position: absolute;
            left: 15%;
        } 
        .Empaty-6Report2 .H1Text{
            position: absolute;
            top: 20%;
            right: 90px;
            width: 80%;
            color: var(--BackGround-color-Blue);
            font-family: var(--Bold-Font-Family);
            text-align: right;
        }
        .Empaty-6Report2 .BigText{
            position: absolute;
            top: 32%;
            right: 50px;
            color: var(--Font-Color);
            font-family: var(--Regular-Font-Family);
            text-align: right;
            width: 90%;
            font-size: 25px;
            line-height: 47px;
            direction: rtl;
        }
        /* End Empaty-6Report2 */

        /* Start ManyBox-1Report-2 */
        .ManyBox-1Report-2{
            position: relative;
        }
        .ManyBox-1Report-2 .IntroBatrn{
            position: absolute;
            left: 22%;
        } 
        .ManyBox-1Report-2 .BoxsContaner{
            position: absolute;
            top: 280px;
            left: 135px;
            width: 835px;
            height: 951px;
        }
        .ManyBox-1Report-2 .BoxsContaner .SmallBox{ 
            width: 100%;
            height: 80px;
            display: flex;
            position: relative;
            top: 13px;
            font-family: var(--Regular-Font-Family);     
        }
        .ManyBox-1Report-2 .BoxsContaner .SmallBox div:nth-child(1){ 
            width: 50%;
            position: absolute;
            right: 0;
        }
        .ManyBox-1Report-2 .BoxsContaner .SmallBox div:nth-child(1) span{ 
            display: inline-block;
            width: auto;
            height: auto;
            padding: 9px;
            font-size: 25px;
            font-weight: 600;
            font-family: var(--font-family);
            color: var(--BackGround-color-Blue);
            border-radius: 12px;
            padding-top: 0;
            border: 3.2px  solid var(--BackGround-color-Blue);    
            position: absolute;
            left: 36px;
        }
        .ManyBox-1Report-2 .BoxsContaner .SmallBox div:nth-child(2){ 
            width: 50%;
            position: absolute;
            left: 0;
        }
        .ManyBox-1Report-2 .BoxsContaner .SmallBox div:nth-child(2) span{ 
            display: inline-block;
            width: auto;
            height: auto;
            padding: 9px;
            font-size: 25px;
            font-weight: 600;
            font-family: var(--font-family);
            color: var(--BackGround-color-Blue-Sco);
            border-radius: 12px;
            padding-top: 0;
            border: 3.2px  solid var(--BackGround-color-Blue-Sco);   
            position: absolute;
            right: 36px;
        }
        .ManyBox-1Report-2 .BoxsContaner .BigBox{
            position: relative;
            width: 100%;
            height: 91.5%;
            font-family: var(--Regular-Font-Family);
            display: flex;
            flex-direction: column;
            gap: 11.85px;
        }
        .ManyBox-1Report-2 .BoxsContaner .BigBox .DetilsBoxs{
            width: 100%;
            height: 86px;
            border-radius: 28px;
            display: flex;
        }
        .ManyBox-1Report-2 .BoxsContaner .BigBox .DetilsBoxs span{
            font-size: 18px;
        font-weight: 900;
            font-family: var(--Bold-Font-Family);
            text-align: center;
            color: white;
            padding: 4px;
        }
        .ManyBox-1Report-2 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(1){
            position: relative;
            left: 6px;
            width: 100px;
            height: 87%;
            margin: auto 0;
            border-radius: 20px;
            border-bottom-left-radius: 28px;
            border-top-left-radius: 28px;
        }
        .ManyBox-1Report-2 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(1) span{
            position: relative;
            top: 25%;
        }
        .ManyBox-1Report-2 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(2){
            position: relative;
            left: 40px;
            width: 269px;
            height: 87%;
            border-top-left-radius: 11px;
            border-bottom-left-radius: 11px;
            background-color: var(--BackGround-color-Blue-Sco);
            padding: 0 12px;
            display: flex;
            justify-items: center;
            align-items: center;
            margin: auto 0;
        }
        .ManyBox-1Report-2 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(3){
            position: relative;
            left: 49px;
            width: 269px;
            height: 87%;
            border-top-right-radius: 11px;
            border-bottom-right-radius: 11px;
            background-color: var(--BackGround-color-Blue);
            padding: 0 12px;
            display: flex;
            justify-items: center;
            align-items: center;
            margin: auto 0;
        }
        .ManyBox-1Report-2 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(4){
            position: relative;
            left: 91px;
            width: 100px;
            height: 90%;
            border-radius: 20px;
            border-bottom-right-radius: 28px;
            border-top-right-radius: 28px;
            margin: auto 0;
        }
        .ManyBox-1Report-2 .BoxsContaner .BigBox .DetilsBoxs .ImportBox:nth-child(4) span{
            position: relative;
            top: 25%;
        }
        /* End ManyBox-1Report-2 */

        /* Start Empaty-7Report2 */
        .Empaty-7Report2{
            position: relative;
        }
        .Empaty-7Report2 .IntroBatrn{
            position: absolute;
            left: 16%;
        } 
        .Empaty-7Report2 .H1Text{
            position: absolute;
            top: 20%;
            right: 80px;
            width: 80%;
            color: var(--BackGround-color-Gray);
            font-family: var(--Bold-Font-Family);
            text-align: right;
        }
        .con5Child 
        {
            background-color: var(--BackGround-color-Red);
            color: #fff !important;
        }
        .con5Child span {
            color: #fff !important;

        }
        .con6Child 
        {
            background-color: var(--BackGround-color-Red-Sco);
            color: #fff !important;
        }
        .con6Child span {
            color: #fff !important;

        }
        .con7Child
        {
            background-color: var(--BackGround-color-Green);
            color: #fff;
        }
        .con7Child span {
            color: #fff !important;

        }
        .con8Child
        {
            background-color: var(--BackGround-color-Green-Sco);
            color: #fff;
        }
        .con8Child span {
            color: #fff !important;

        }
        .Empaty-7Report2 .BigText{
            position: absolute;
            top: 31%;
            right: 50px;
            color: var(--BackGround-color-Gray);
            font-family: var(--Regular-Font-Family);
            font-size: 25px;
            text-align: right;
            width: 90%;
            line-height: 41px;
            direction: rtl;
        }
        /* End Empaty-7Report2 */
    
    /* END REPORT 2 */
